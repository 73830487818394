import React from "react";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const Loader = ({ showValidationSpinner }) => {
  return (
    <>
      <OverlayContainer></OverlayContainer>
      <SpinnerContainer>
        <SpinnerBox>
          <Box sx={{ display: "flex" }}>
            <CircularProgress size="100px" color="primary" />
          </Box>
          <LoadingContent>
            {showValidationSpinner ? "Validating" : "Please wait"}
          </LoadingContent>
        </SpinnerBox>
      </SpinnerContainer>
    </>
  );
};

const OverlayContainer = styled.div`
  width: 100%;
  height: 100%;
  background: black;
  opacity: 30%;
  position: fixed;
  z-index: 41;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: auto;
`;

const SpinnerContainer = styled.div`
  width: 250px;
  height: 250px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 50;
`;

const SpinnerBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

const LoadingContent = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

export default Loader;