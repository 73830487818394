import axios from "axios";

import {
  GET_QQ_CUSTOMER_DATA
} from "../constants/urls";

//get QuickQuote Session

export const GetCustomerDetails = async (fxbuyflowsessionId) => {
  try {
    const res = await axios.post(GET_QQ_CUSTOMER_DATA, {
      fxbuyflowsessionId: fxbuyflowsessionId,
    });
    if (res.status === 200) {
      return res.data;
    }
    return res;
  } catch (err) {
    return Promise.reject(err);
  }
};
