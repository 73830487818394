import React from "react";
import Label from "../utility/Label";
import QuickQuoteTitle from "../utility/title";
import WhiteCard from "../utility/whitecard";
import styled from "styled-components";
import Input from "../utility/Input";
import QQButton from "../utility/Button";
import { updateEmailInContactInfo } from "../constants";

const SendEmailToCustomer = ({customerEmail, emailResultStatus, emailQQToCustomer, showResendQuickQuote}) => {
  const btnDisableSendToCustomer = {
    btnDisable: (customerEmail === updateEmailInContactInfo) 
  }
  return (
    <div>
      <QuickQuoteTitle titleName="SEND TO CUSTOMER" />
      <br />
      <WhiteCard>
        <InputSection>
          <Label labelName="Email Address" />
          <Input type="emailaddress" inputValue={customerEmail} inputDisable />
        </InputSection>
        <InputSection>
          <Label labelName="Status" />
          <Input type="text" inputValue={emailResultStatus} inputDisable />
        </InputSection>
      </WhiteCard>
      <QQButton  btnText={showResendQuickQuote ? "RESEND QUICK QUOTE": "SEND QUICK QUOTE"} onClick={emailQQToCustomer} {...btnDisableSendToCustomer} floatRight></QQButton>
    </div>
  );
};

const InputSection = styled.div`
  display: grid;
  grid-template-columns: 175px 380px auto;
  justify-items: right;
  align-items: center;
  margin-bottom: 10px;
`;

export default SendEmailToCustomer;
