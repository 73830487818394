import React from "react";
import styled from "styled-components";

const QQButton = (props) => {
  const btnProps = {
    disabled: props.btnDisable 
  };
  return <Button onClick={props.onClick} alignment={!!props.floatRight ? "right" : ""} {...btnProps}>{props.btnText}</Button>;
};

const Button = styled.button`
  height: 30px;
  padding: 0 30px;
  min-width: 140px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.499;
  display: inline-block;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 0;
  cursor: ${({disabled}) => disabled ? "not-allowed" : "pointer"};
  font-size: 14px;
  outline: none;
  background-color: ${({disabled}) => disabled ? "#d3d3d3" : "#1890ff"};
  color: #ffffff;
  border-color: ${({disabled}) => disabled ? "#d3d3d3" : "#1890ff"};
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  float: ${(props) => props.alignment};
`;

export default QQButton;
