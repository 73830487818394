import React from "react";
import QuickQuoteTitle from "../utility/title";
import styled from "styled-components";
import WhiteCard from "../utility/whitecard";
import Input from "../utility/Input";
import Label from "../utility/Label";
import PropTypes from "prop-types";
import TnTextBox from "../utility/tnTextBox";
import { formatPhoneNumber } from "../utility/helper";
import { CustomerPhoneNumber } from "../../entities/customer";

const ContactInformation = ({ contactInfoProps }) => {
  const onClickToggleSection =()=>{
    contactInfoProps.setToggleSection(
      {
      ...contactInfoProps.toggleSection,
      customerContact:!contactInfoProps.toggleSection.customerContact
      }
    )
  }

  const onInputChange = (e) => {
    const regExp = /^[0-9\b]+$/;
    const regState = /^[A-Za-z]+$/;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    let isValid = true;
    if ((inputValue !== "" && inputName === "zip") || (inputValue !== "" && inputName === "phoneExt") ) {
      if (!regExp.test(inputValue)) {
        isValid = false;
      }
    }

    if (inputValue !== "" && inputName === "state") {
      if (!regState.test(inputValue)) {
        isValid = false;
      }
      inputValue = e.target.value.toUpperCase();
    }

    if (inputName === "address1" || inputName === "address2" || inputName === "state" || inputName === "zip" || inputName === "city") {
      contactInfoProps.setIsValidAddress(false);
    }
    
    if (isValid) {
      contactInfoProps.setContactInformation({
        ...contactInfoProps.customerContactInformation,
        [e.target.name]: inputValue
      });
    }
  };

  const onPhoneNumberInputChange = (e) => {
    let contactPhval = formatPhoneNumber(e.target.value);

    const plainPhValue = contactPhval.replace(/[^\d]/g, "");

    if (plainPhValue.length < 11) {
      contactInfoProps.setContactInformation({
        ...contactInfoProps.customerContactInformation,
        phoneNumber: new CustomerPhoneNumber(
          plainPhValue,
          formatPhoneNumber(plainPhValue)
        ),
      });
    }
  };

  const onEditContactInfoClick = () => {
    contactInfoProps.setContactInfoEdited(true);
  };
  
  return (
    <>
      <ContactInformationSection>
        <QuickQuoteTitle titleName="CONTACT INFORMATION" onClick={() => onClickToggleSection()} showSection={contactInfoProps.toggleSection.customerContact} showToggleBtn={true}/>
      </ContactInformationSection>
      <ContactInformationContainer showSection={contactInfoProps.toggleSection.customerContact}>
        <WhiteCard>
          {contactInfoProps.isContactInfoEdited ? (
            <>
              <InputSection>
                <Label labelName="Name" isRequired={true} />
                <Input
                  type="text"
                  maxLength="200"
                  inputName="name"
                  inputValue={contactInfoProps.customerContactInformation.name}
                  inputOnChange={onInputChange}
                />
              </InputSection>
              <InputSection>
                <Label labelName="Business Name" isRequired={true} />
                <Input
                  type="text"
                  maxLength="200"
                  inputName="businessName"
                  inputValue={
                    contactInfoProps.customerContactInformation.businessName
                  }
                  inputOnChange={onInputChange}
                />
              </InputSection>
              <InputSection>
                <Label labelName="Address Line 1" isRequired={true} />
                <Input
                  type="text"
                  maxLength="200"
                  inputName="address1"
                  inputValue={
                    contactInfoProps.customerContactInformation.address1
                  }
                  inputOnChange={onInputChange}
                />
              </InputSection>
              <InputSection>
                <Label labelName="Address Line 2" />
                <Input
                  type="text"
                  maxLength="200"
                  inputName="address2"
                  inputValue={
                    contactInfoProps.customerContactInformation.address2
                  }
                  inputOnChange={onInputChange}
                />
              </InputSection>
              <InputSection>
                <Label labelName="City" isRequired={true} />
                <Input
                  type="text"
                  maxLength="200"
                  inputName="city"
                  inputValue={contactInfoProps.customerContactInformation.city}
                  inputOnChange={onInputChange}
                />
              </InputSection>
              <InputSection>
                <Label labelName="State/Province" isRequired={true} />
                <Input
                  type="text"
                  maxLength="2"
                  inputName="state"
                  inputValue={contactInfoProps.customerContactInformation.state}
                  inputOnChange={onInputChange}
                />
              </InputSection>
              <InputSection>
                <Label labelName="ZIP Code" isRequired={true} />
                <Input
                  type="text"
                  maxLength={5}
                  inputName="zip"
                  inputValue={contactInfoProps.customerContactInformation.zip}
                  inputOnChange={onInputChange}
                />
              </InputSection>
              <InputSection>
                <Label labelName="Email Address" isRequired={true}/>
                <Input
                  type="email"
                  maxLength="100"
                  inputName="email"
                  inputValue={contactInfoProps.customerContactInformation.email}
                  inputOnChange={onInputChange}
                />
              </InputSection>
              <InputSection>
                <Label labelName="Phone Number" isRequired={true} />
                <TnTextBox
                  id={1}
                  value={
                    contactInfoProps.customerContactInformation.phoneNumber
                  }
                  handleChange={(e) => onPhoneNumberInputChange(e)}
                />
                {/* <Input
                  // isPhoneNumberInput
                  // inputOnChange={onPhoneNumberInputChange}
                  inputValue={
                    contactInfoProps.customerContactInformation.phoneNumber
                  }
                /> */}
              </InputSection>
              <InputSection>
                <Label labelName="Phone Ext." />
                <Input
                  type="text"
                  maxLength="5"
                  inputName="phoneExt"
                  inputOnChange={onInputChange}
                  inputValue={
                    contactInfoProps.customerContactInformation.phoneExt
                  }
                />
              <div>Scrubbed</div>
              <ScrubbedAddressInput
                id="addresscheckbox"
                type="checkbox"
                checked={contactInfoProps.isValidAddress}
                readOnly
              />
              </InputSection>
            </>
          ) : (
            <ContactInfoReadOnlyContainer>
              <span>{contactInfoProps.customerContactInformation.name}</span>
              <span>
                {contactInfoProps.customerContactInformation.businessName}
              </span>
              <span>
                {contactInfoProps.customerContactInformation.address1}
              </span>
              <span>{contactInfoProps.customerContactInformation.address2}</span>
              <span>
                {contactInfoProps.customerContactInformation.city &&
                  contactInfoProps.customerContactInformation.city + ", "}
                {contactInfoProps.customerContactInformation.state}{" "}
                {contactInfoProps.customerContactInformation.zip}
              </span>
              <span>{contactInfoProps.customerContactInformation.email}</span>
              <span>
                {contactInfoProps.customerContactInformation?.phoneNumber?.displayValue}
              </span>
              <span>
                {contactInfoProps.customerContactInformation?.phoneExt && "Phone Ext. " + contactInfoProps.customerContactInformation?.phoneExt}
              </span>

              {contactInfoProps.customerContactInformation.name && (
                <AddressGrid>
                <EditContactLink>
                  <ContactInfoButton onClick={onEditContactInfoClick}>
                    Edit Contact Information
                  </ContactInfoButton>
                </EditContactLink>
                <ScrubbedLabel>Scrubbed</ScrubbedLabel>
                <ScrubbedAddressInput
                  id="addresscheckbox"
                  type="checkbox"
                  checked={contactInfoProps.isValidAddress}
                  readOnly
                />
                </AddressGrid>
              )}
            </ContactInfoReadOnlyContainer>
          )}
        </WhiteCard>
      </ContactInformationContainer>
    </>
  );
};

//#region Lines proptypes
ContactInformation.propTypes = {
  customerContactInformation: PropTypes.array,
  setContactInformation: PropTypes.array,
  contactValidationList: PropTypes.array,
  setContactValidationList: PropTypes.array,
};
//#endregion

const ContactInformationSection = styled.div`
  padding: 10px;
  padding-top: 20px;
`;

const ContactInfoButton = styled.div`
  background: transparent;
  border: none;
  font-size: 14px;
  padding-left: 0px;
  color: blue;
  text-decoration: underline blue;
  cursor: pointer;
  max-width: 190px;
`;

const EditContactLink = styled.div`
  padding-top: 20px;
`;

const ContactInformationContainer = styled.div`
  ${({showSection}) => showSection ? `
  padding: 10px;      
  `: ''}
  max-height: 0;
  ${({showSection}) => showSection ? `
    max-height: 1000px;      
  `: ''}
  transition: max-height 0.15s linear; 
  overflow: hidden;
`;

const ContactInfoReadOnlyContainer = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 20px 0 20px 0;
`;

const InputSection = styled.div`
  display: grid;
  grid-template-columns: 175px auto 320px 30px 20px;
  justify-items: right;
  align-items: center;
  margin-bottom: 10px;
`;

const ScrubbedLabel = styled.div`
  margin: auto;
`;

const AddressGrid = styled.div`
  display: grid;
  grid-template-columns: auto 0px 110px;
`;

const ScrubbedAddressInput = styled.input`
  margin: auto 0px auto 70px;
  width: 20px;
  height: 20px;
`;

export default ContactInformation;
