import React from "react";
import styled from "styled-components";

const QuickQuoteTitle = (props) => {
  return (
    <div>
      <QuickQuoteTitleStyle
        alignment={!!props.isCenter ? "center" : "left"}
        textCase={!!props.normalText ? "" : "uppercase"}
      >
        {props.titleName}
        {props.showToggleBtn ? (
          <div
            onClick={props.onClick}
            style={{ cursor: "pointer", color: "rgb(24, 144, 255)",textAlign: "right" }}
          >
            {props.showSection ? "HIDE" : "SHOW"}
          </div>
        ) : null}
      </QuickQuoteTitleStyle>
      <QuickQuoteHorizontal
        marginbottom={!!props.isMarginBottom ? "20px" : ""}
      ></QuickQuoteHorizontal>
    </div>
  );
};

const QuickQuoteTitleStyle = styled.div`
  text-transform: ${(props) => props.textCase};
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 800;
  color: rgba(0, 0, 0, 0.75);
  text-align: ${(props) => props.alignment};
  display: grid;
  grid-template-columns: auto 50px;
`;

const QuickQuoteHorizontal = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e8e8e8;
  font-size: 14px;
  line-height: 1.5;
  list-style: none;
  background: #e8e8e8;
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  height: 2px;
  margin: 0;
  margin-bottom: ${(props) => props.marginbottom};
`;

export default QuickQuoteTitle;
