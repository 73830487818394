import { getEnv } from '../utils/env'

const { HOST_ENV, REACT_APP_API_BASE_URL, REACT_APP_SALESFORCE_URL, ...ENV } = getEnv()

const BASE_API_URL = REACT_APP_API_BASE_URL;

const joinURL = url => BASE_API_URL + ENV[`REACT_APP_${url}`];

export const GET_TOOLTIPS = joinURL('GET_TOOLTIPS');
export const GET_QUOTE = joinURL('GET_QUOTE');
export const POST_SAVE_QUOTE = joinURL('POST_SAVE_QUOTE');
export const GET_QQ_SESSION_DATA = joinURL('POST_QQ_SESSION');
export const GET_QQ_CUSTOMER_DATA = joinURL('POST_CUSTOMERINFO');
export const DELETE_QUOTE = joinURL('DELETE_QUOTE');
export const POST_VALIDATE_IMEI = joinURL('POST_VALIDATE_IMEI');
export const CALCULATE_QUOTE = joinURL('POST_CALCULATE_QUOTE');
export const POST_QQ_SEND_EMAIL_TO_CUSTOMER = joinURL('POST_SEND_EMAIL');
export const GET_QQ_SEND_EMAIL_TO_CUSTOMER = joinURL('GET_QQ_EMAIL_DETAILS');
export const POST_QQ_STANDARD_ADDRESS = joinURL('POST_STANDARD_ADDRESS');

export const GET_SALESFORCE_URL = REACT_APP_SALESFORCE_URL;
