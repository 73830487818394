import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styled from "styled-components";

const ScrubbedAddressTable = (props) => {
  const [addressSelected, setAddressSelected] = useState(-1);

  const onChangeRadioBtn = (id) => {
    setAddressSelected(id);
    props.setBtnApplyDisabled(false);
    props.validAddressList.map((d, i) => {
      const selectedAddress = { ...d };
      if (id === i) {
        props.setValidAddress({
          "address1": selectedAddress.addressLine1,
          "address2": selectedAddress.addressLine2,
          "city": selectedAddress.city,
          "state": selectedAddress.state,
          "zip": selectedAddress.zipCode,
          "isValidAddress": true
        });
      }
      return selectedAddress;
    }
    );
  };

  const onClickBtn = () => {
    props.setBtnApplyDisabled(true);
    setAddressSelected(-1);
  };

  return (
    <StyledContainer>
      <Table>
        <Header>
          <TableHeaderRow>
            <TableHeader>
              <UnSelectButton onClick={onClickBtn}>
                <FontAwesomeIcon icon="fa-solid fa-minus" size="xs" />
              </UnSelectButton>
            </TableHeader>
            <TableHeader>Address</TableHeader>
            <TableHeader>Latitude/Longitude</TableHeader>
          </TableHeaderRow>
        </Header>
        <TableBody>
          {props.validAddressList.map((x, id) => (
            <TableBodyRow key={id}>
              <TableColumn>
                <AddressInput type="radio"
                  value={id}
                  name="address-radio"
                  checked={id === addressSelected}
                  onChange={(e) => onChangeRadioBtn(id)} />
              </TableColumn>
              <TableColumn>
                {x.addressLine1}, <br />
                {x.addressLine2 && (() => (<>{x.addressLine2}, <br/></>))()}
                {x.city}, {x.state}, {x.zipCode}</TableColumn>
              <TableColumn>{x.latitude}/{x.longitude}</TableColumn>
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

const UnSelectButton = styled.button`
  cursor: pointer;
  background-color: white;
  margin-left: 15px;
`;

const Table = styled.table`
  width: 99%;
  border-collapse:collapse;
`;

const Header = styled.thead`
  text-align: left;
  background: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  position: sticky;
  top: 0;
`;

const TableBody = styled.tbody``;

const TableBodyRow = styled.tr`
border-bottom: 1px solid rgb(204, 204, 204);
`;

const TableHeaderRow = styled.tr`
`;

const TableHeader = styled.th`
  font-size: 1.1rem;
  padding: 10px 15px;
  border-right: 1px solid rgb(204, 204, 204)
`;

const TableColumn = styled.td`
padding: 10px 15px;
border-right: 1px solid rgb(204, 204, 204);
border-left: 1px solid rgb(204, 204, 204);
vertical-align: top;
font-size: 14px;
`;

const AddressInput = styled.input`
margin-left: 20px;
`;

export default ScrubbedAddressTable;