import { Byod, ByTheGig, Unlimited, Savings, Quote as FinalQuote, PortTn, PortImei, UnlimitedIntro, UnlimitedPlus, UnlimitedPremium } from "../../entities/quote"
import Configuration from "../configuration/configuration";
import postPayload from "../../data/post.json";
import Cart from "../cart/cart";
import React, { useState, useEffect, useCallback } from "react";
import Header from "./Header/header";
import styled from "styled-components";
import { 
  defaultPaymentDropdown,
  defaultDeviceSelection,
  cartItemDetails,
  // COMMENTED: SEND TO CUSTOMER
  emailSentStatus,
  emailSuccessStatus,
  emailNotSentStatus,
  emailExhaustedStatus,
  updateEmailInContactInfo,
  fulfilled,
  unlimitedIntroType,
  unlimitedExistingType 
} from '../constants';
import AlertDialog from "../utility/alert";
import { GetPcatResponse } from "../../services/configuration";
import { GetCustomerDetails } from "../../services/customer";
// COMMENTED: SEND TO CUSTOMER
import { SendQQToCustomer, GetSendQQToCustomerDetails } from "../../services/email";
import Loader from "../utility/loader";
import { Cart as CartModel, CartItem} from "../../entities/cart";
import { SaveConfigCart, ClearCart, CalculateCart } from "../../services/cart";
import { ERRORS } from "../../constants/errors";
import { Customer, CustomerAddress, CustomerContactInfo, CustomerPhoneNumber } from "../../entities/customer";
import { SUCCESS } from "../../constants/success";
import Notification from "../utility/notify";
import { Toggle } from "../../entities/toggle";
//import Banner from "./AnnouncementBanner/banner";
import { useNavigate } from "react-router-dom";
// COMMENTED: SEND TO CUSTOMER
import SendEmailToCustomer from "../customer/sendtocustomer";
import { ValidateAddress } from "../../services/validation";
import ScrubbedAddressPopup from "../utility/scrubbedAddressPopup";
// import Footer from "./Footer/footer";

const Quote = ({ qqSession }) => {

  const navigate = useNavigate();
  //constants
  const cartCalcDecider = "BE";
  //const bannerText = "Here we goooo !! Devices are now available with capacities !!";
  //const [showBanner, setShowBanner] = useState(true);

  //#region State: Dropdown items
  //from dynamo
  const [unlimitedLinesList, setUnlimitedLinesList] = useState([]);
  const [unlimitedIntroLinesList, setUnlimitedIntroLinesList] = useState([]);
  const [unlimitedComboLinesList, setUnlimitedComboLinesList] = useState([]);
  const [unlimitedTypeList, setUnlimitedTypeList] = useState([]);
  const [gigDataUsageList, setGigDataUsageList] = useState([]);
  const [cspList, setCspList] = useState([]);
  const [toolTips, setToolTips] = useState([]);
  //pcat device list - from redis cache
  const [deviceList, setDeviceList] = useState([]);
  //#endregion

  //#region State: Config section selections
  const [unlimitedLines, setUnlimitedLines] = useState(0);
  const [unlimitedIntroLines, setUnlimitedIntroLines] = useState(0);
  const [unlimitedPlusLines, setUnlimitedPlusLines] = useState(0);
  const [unlimitedPlusLinesUnitPrice, setUnlimitedPlusLinesUnitPrice] = useState(0);
  const [unlimitedPremiumLines, setUnlimitedPremiumLines] = useState(0);
  const [unlimitedPremiumLinesUnitPrice, setUnlimitedPremiumLinesUnitPrice] = useState(0);
  const [unlimitedType, setUnlimitedType] =useState(-1);
  const [gigLines, setGigLines] = useState(0);
  const [gigDataUsage, setGigDataUsage] = useState(-1);
  const [byodLines, setByodLines] = useState(0);
  const [deviceLines, setDeviceLines] = useState(0);
  const [device, setDevice] = useState([defaultDeviceSelection]);
  const [paymentMethod, setPaymentMethod] = useState("ONETIME");
  const [cms, setCms] = useState(0);
  const [csp, setCsp] = useState("-1");
  const [cart, setCart] = useState(new CartModel(null, null, null));
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState();
  const [successMessage, setSuccessMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [paymentDropdownList, setPaymentDropdownList] = useState([defaultPaymentDropdown]);
  const [customerInformation, setCustomerInformation] = useState(new Customer(null, "", ""));
  const [notify, setNotify] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState("")
  const [btnApplyDisabled, setBtnApplyDisabled] = useState(true);

  const [prevQuoteRefresh, setPrevQuoteRefresh] = useState(false);
  const [lastQuoteSavedDate, setLastQuoteSavedDate] = useState("");
  //#endregion

  //#region : Edit Contact Info
  const [customerContactInformation, setContactInformation] = useState(new CustomerContactInfo(null, null, null, null, null, null, null, null, null, null));
  const [isContactInfoEdited, setContactInfoEdited] = useState(false);
  const [isValidAddress, setIsValidAddress] = useState(false);
  const [validAddressList, setValidAddressList] = useState([]);
  const [validAddress, setValidAddress] = useState(new CustomerAddress(null, null, null, null, null, null));
  const [popup, setPopup] = useState(false);
  //#endregion

  //#region State: Tn Validation 
  const [tns, setTns] = useState([]);
  const [showValidationSpinner, setValidationSpinner] = useState(false);
  const [portTn, setPortTn] = useState(0);
  //#endregion

  //#region State: IMEI Validation
  const [imei, setImei] = useState([]);  
  //#endregion

  const [toggleSection, setToggleSection] =useState(new Toggle(true,true,true,true));
  
  //#region State: Lines 
  const [totalLines, setTotalLines] = useState(0);
  const [linesList, setLinesList] = useState([]);
  //#endregion

  // COMMENTED: SEND TO CUSTOMER
  //#region State: Send to Customer 
  const [customerContactEmail, setcustomerContactEmail] = useState(updateEmailInContactInfo);
  const [emailResultStatus, setEmailResultStatus] = useState("Not Sent");
  const [showResendQuickQuote, setshowResendQuickQuote] = useState(false);
  //#endregion

  //#region useCallbacks
  const setDropdownValues = useCallback((pcat) => {
    if(!!pcat) {
       //Lines dropdowns list setup
       if(!!pcat.totalLines) {
        setTotalLines(pcat.totalLines);
        const list = Array.from({ length: pcat.totalLines + 1 }, (_, i) => i);
        setLinesList(list);
      }

      // UnlimitedPlus price
      if(!!pcat.unlimitedPlus) {
        setUnlimitedPlusLinesUnitPrice(pcat.unlimitedPlus);
      }

       // UnlimitedPremium price
       if(!!pcat.unlimitedPremium) {
        setUnlimitedPremiumLinesUnitPrice(pcat.unlimitedPremium);
      }

      //Unlimited dropdown
      if(!!pcat.unlimited) {
        //As of now let us go with the static list with 10 lines
        setUnlimitedLinesList(pcat.unlimited);
      }

      //UnlimitedType dropdown
      if(!!pcat.unlimitedType) {
        setUnlimitedTypeList(pcat.unlimitedType);
      }

      //UnlimitedIntro dropdown
      if(!!pcat.unlimitedIntro) {
        setUnlimitedIntroLinesList(pcat.unlimitedIntro);
      }

      //UnlimitedCombo dropdown
      if(!!pcat.unlimitedCombo) {
        setUnlimitedComboLinesList(pcat.unlimitedCombo);
      }
      //Tooltip 
      if(!!pcat.toolTips) {
        setToolTips(pcat.toolTips);
      }

      //By the gig
      //Data usage
      if(!!pcat.byTheGig) {
        setGigDataUsageList(pcat.byTheGig);
        //Lines
        //As of now let us go with the static list with 10 lines
      }

      //Byod
      //Lines
      //As of now let us go with the static list with 10 lines
      
      //Device
      //PcatDeviceList
      if(!!pcat.pcat) {
        setDeviceList(pcat.pcat);
      }

      //Csp
      if(!!pcat.currentMobileProvider) {
        setCspList(pcat.currentMobileProvider);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setConfigSelections = useCallback((pcat) => {
    if(!!pcat) {
      const previousQuote = pcat.previousQuote;
      if(!!previousQuote) {

        setLastQuoteSavedDate(previousQuote.lastQuoteSaved);
        //Unlimited
        const unlimitedLinesSelection = previousQuote.unlimited.quantity;
        if(!!unlimitedLinesSelection) {
          setUnlimitedLines(unlimitedLinesSelection);
        }

         //UnlimitedType
         const unlimitedTypeSelection = previousQuote.unlimitedType;
         if(!!unlimitedTypeSelection) {
          setUnlimitedType(unlimitedTypeSelection);
         }

        //UnlimitedIntro
        const unlimitedIntroLinesSelection = previousQuote.unlimitedIntro?.quantity;
        if(!!unlimitedIntroLinesSelection) {
          setUnlimitedIntroLines(unlimitedIntroLinesSelection);
        }

        //UnlimitedPlus 
        const unlimitedPlusLinesSelection = previousQuote.unlimitedPlus?.quantity;
        if(!!unlimitedPlusLinesSelection) {
          setUnlimitedPlusLines(unlimitedPlusLinesSelection);
        }

        //UnlimitedPremium
        const unlimitedPremiumLinesSelection = previousQuote.unlimitedPremium?.quantity;
        if(!!unlimitedPremiumLinesSelection) {
          setUnlimitedPremiumLines(unlimitedPremiumLinesSelection);
        }
  
        //By the gig
        //Data usage
        const byTheGigDataUsageSelection = previousQuote.byTheGig.dataUsage;
        if(!!byTheGigDataUsageSelection) {
          setGigDataUsage(byTheGigDataUsageSelection);
        }
        
        //Port Tn
        const portTn = previousQuote.tns.portTn;
        if(!!portTn) {
          setPortTn(portTn);
        }

        //Tns List
        const tnsList = previousQuote.tns.tns;
        if(!!tnsList) {
          setTns(tnsList);
        }

        //Number of lines
        const byTheGigLinesSelection = previousQuote.byTheGig.quantity;
        if(!!byTheGigLinesSelection) {
          setGigLines(byTheGigLinesSelection);
        }
  
        //byod
        const byodLinesSelection = previousQuote.byod.quantity;
        if(!!byodLinesSelection) {
          setByodLines(byodLinesSelection);
        }
  
        //Imei List
        const imeiList = previousQuote.imei.imeiNums;
        if(!!imeiList) {
          setImei(imeiList);
        }

        //devices
        if(previousQuote.devices.length > 0) {
          // const devicesSelection = DeviceMapper(previousQuote.devices);
          const devicesSelection = previousQuote.devices;
          const pcatDevices = pcat.pcat;
          //The below change is made to ensure the correct order of payment dropdowns        
          const paymentOptList = devicesSelection.map(d => pcatDevices.find(x => x.productCode === d.deviceDetail.id)).map(x => x.paymentOptions.map(p => ({id: p.id, name: p.name})));      
          //const paymentOptList = pcatDevices.filter(x => devicesSelection.map(d => d.deviceDetail.id).includes(x.productCode)).map(x => x.paymentOptions.map(p => ({id: p.id, name: p.name})));      
          setPaymentDropdownList(paymentOptList);
          setDevice(devicesSelection);
        }
  
        //Current monthly bill
        if(!!previousQuote.savings) {
          //current savings
          setCms(+previousQuote.savings.currentMonthlyBill)
          //current service provider
          setCsp(previousQuote.savings.currentMobileProvider)
        }
      } else {
        //show server error popup saying something went wrong
      }
    }
  },[]);

  const setCartSummary = useCallback((pcat) => {
    if(!!pcat) {
      //Cart summary
      if(!!pcat.previousQuote.cart) {
        setCart(pcat.previousQuote.cart);
      }      
    }
  }, [setCart]);

  const setCustomerInfo = useCallback((customerResponse) => {
    if(customerResponse){
      setCustomerInformation(customerResponse);
    }
  }, [])

  // COMMENTED: SEND TO CUSTOMER
  const setSendToCustomerInfo = useCallback(sendToCusomerResponse => {
    if (!!sendToCusomerResponse) {
      if(sendToCusomerResponse.emailStatus.toUpperCase() === emailSentStatus) {
        setEmailResultStatus("Sent to Customer on " + sendToCusomerResponse.sentDate);
        setshowResendQuickQuote(true);
      } else {
        setEmailResultStatus(sendToCusomerResponse.emailStatus);
        setshowResendQuickQuote(false);
      }
    }
  }, []);

  const setContactInfo = useCallback((contactInfoResponse, customerInfoResponse) => {
    // COMMENTED: SEND TO CUSTOMER
    let customerContactEmail = null;
    if(!!contactInfoResponse) {
      const prepareContactInfo = new CustomerContactInfo (
        contactInfoResponse?.name,
        contactInfoResponse?.businessName,
        contactInfoResponse?.address1,
        contactInfoResponse?.address2,
        contactInfoResponse?.city,
        contactInfoResponse?.state,
        contactInfoResponse?.zip,
        contactInfoResponse?.email,
        new CustomerPhoneNumber(contactInfoResponse?.phoneNumber?.value, contactInfoResponse?.phoneNumber?.displayValue),
        contactInfoResponse.phoneExt
      );
      
      setContactInformation(prepareContactInfo);

      // Boolean value to indicate address is scrubbed or not
      setIsValidAddress(contactInfoResponse?.isValidAddress);

      // COMMENTED: SEND TO CUSTOMER
      if (!!contactInfoResponse?.email) {
        setcustomerContactEmail(contactInfoResponse?.email);
        customerContactEmail = contactInfoResponse?.email;
      }
    } else {
      const plainPhValue = customerInfoResponse?.contact?.phone.replace(/[^\d]/g, '');

      const prepareContactInfo = new CustomerContactInfo (
        customerInfoResponse?.contact?.name,
        customerInfoResponse?.contact?.businessName,
        customerInfoResponse?.site?.siteAddress?.address1,
        customerInfoResponse?.site?.siteAddress?.address2,
        customerInfoResponse?.site?.siteAddress?.city,
        customerInfoResponse?.site?.siteAddress?.state,
        customerInfoResponse?.site?.siteAddress?.postalCode,
        customerInfoResponse?.contact?.email,
        new CustomerPhoneNumber(plainPhValue, customerInfoResponse?.contact?.phone),
        customerInfoResponse?.contact?.phoneExt
      );
      
      setContactInformation(prepareContactInfo);

      // COMMENTED: SEND TO CUSTOMER
      if(!!customerInfoResponse?.contact?.email) {
        setcustomerContactEmail(customerInfoResponse?.contact?.email);
        customerContactEmail = customerInfoResponse?.contact?.email;
      }
    }

    // COMMENTED: SEND TO CUSTOMER
    let sendToCustomerEmailResponse = null
    if(!!customerContactEmail && customerContactEmail !== updateEmailInContactInfo) {
      (async () => {
        sendToCustomerEmailResponse = await GetSendQQToCustomerDetails(qqSession.opportunityId, customerContactEmail,qqSession.agentId);
        setSendToCustomerInfo(sendToCustomerEmailResponse);
        setShowLoader(false);
      })(); 
    } else {
      setShowLoader(false);
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  //#endregion

  //#region useEffect
  useEffect(() => {
    (async () => {
      setShowLoader(true);
      //MAKING BOTH CALLS PARALLEL
      let pcatResponse = null;
      let customerInfoResponse = null;
      const pcatResponsePromise = GetPcatResponse(qqSession.opportunityId);
      const customerInfoResponsePromise = GetCustomerDetails(qqSession.fxbuyflowsessionId); //DefaultCustomerInfo - Use this for DEV-PURPOSE
      
      try {
        var resp = await Promise.allSettled([pcatResponsePromise, customerInfoResponsePromise]);                
        var pcatResp = null;
        var customerResp = null;

        if (resp.length === 2) {
          pcatResp = resp[0];
          customerResp = resp[1];
        }

        if(!!pcatResp && pcatResp.status.toUpperCase() === fulfilled && !!pcatResp.value) {
          pcatResponse = pcatResp.value;
        }
        if(!!customerResp && customerResp.status.toUpperCase() === fulfilled && !!customerResp.value) {
          customerInfoResponse = customerResp.value;
        }
      } catch (error) {
        console.log(error);
      }

      // DEPRECATED CODE
      // const pcatResponse = await GetPcatResponse(qqSession.opportunityId);
      // const customerInfoResponse = await GetCustomerDetails(qqSession.fxbuyflowsessionId); //DefaultCustomerInfo - Use this for DEV-PURPOSE

      if(!!pcatResponse && !!customerInfoResponse) {        
        if(pcatResponse?.notifyInfo?.notifyFlag === "Y") {
          showNotificationDialog(pcatResponse.notifyInfo.notifyMessage)
        }
        setCustomerInfo(customerInfoResponse);
        setContactInfo(pcatResponse.customerContactInfo, customerInfoResponse);
        setDropdownValues(pcatResponse);
        setCartSummary(pcatResponse);  

        if(ERRORS.PREV_QUOTE_VALID_CHECK.VALIDATOR(pcatResponse)) {
          setConfigSelections(pcatResponse);
          hideDialog();          
        } else {
          showDialog();
          setPrevQuoteRefresh(true);
          setErrorMessage(ERRORS.PREV_QUOTE_VALID_CHECK.ERR_MSG);
        }
      } else {
        //showDialog();
        //setErrorMessage('Some error occurred. try again later.');
        navigate("/unauthorize");
        setShowLoader(false);
      }
      setShowLoader(false);
    })();
  },[
    setConfigSelections,
    setCartSummary,
    setDropdownValues,
    setCustomerInfo,
    qqSession,
    setContactInfo,
    navigate,
    // COMMENTED: SEND TO CUSTOMER
    setSendToCustomerInfo
  ]);  // eslint-disable-line react-hooks/exhaustive-deps
  //#endregion

  //#region Utility Functions
  const showDialog = () => {
    setIsValid(false);
    setOpen(true); 
  };
  
  const hideDialog = () => {
    setIsValid(true);
    setOpen(false);
    setErrorMessage(""); 
  };

  const showNotificationDialog = (notificationMessage) => {
    setNotify(true);
    setNotificationMessage(notificationMessage);
  };
  
  const hideNotificationDialog = () => {
    setNotify(false);
    setNotificationMessage(""); 
  };

  const showPopupDialog = () => {
    setPopup(true);
  };
  
  const hidePopupDialog = () => {
    setPopup(false);
    setBtnApplyDisabled(true);
  };

  //#endregion

  //#region Prop grouping - Config Props
  const configProps = {
    unlimitedLines,
    unlimitedIntroLines,
    unlimitedPlusLines,
    unlimitedPremiumLines,
    unlimitedType,
    unlimitedTypeList,
    gigLines,
    gigDataUsage,
    byodLines,
    deviceLines,
    device,
    cms,
    csp,
    paymentMethod,
    paymentDropdownList,
    gigDataUsageList,
    cspList,
    deviceList,
    toolTips,
    tns,
    customerContactInformation,
    isContactInfoEdited,
    portTn,
    linesList,
    totalLines,
    imei,
    toggleSection,
    isValidAddress,
    validAddress,
    setUnlimitedLines,
    setUnlimitedIntroLines,
    setUnlimitedPlusLines,
    setUnlimitedPremiumLines,
    setUnlimitedType,
    setUnlimitedTypeList,
    setGigLines,
    setGigDataUsage,
    setByodLines,
    setDeviceLines,
    setDevice,
    setCms,
    setCsp,
    setPaymentMethod,
    setIsValid,
    setErrorMessage,
    setOpen,
    setPaymentDropdownList,
    setTns,
    setShowLoader,
    setValidationSpinner,
    setContactInformation,
    setContactInfoEdited,
    setPortTn,
    setIsSuccess,
    setSuccessMessage,
    showDialog,
    setImei,
    setToggleSection,
    // COMMENTED: SEND TO CUSTOMER    
    setcustomerContactEmail,
    setIsValidAddress,
    setValidAddress
  };
  //#endregion

  //#region Handlers
  const clearCart = () => {
    setShowLoader(true);
    ClearCart(qqSession.opportunityId)
    .then(res => {
      setUnlimitedLines(0);
      setUnlimitedIntroLines(0);
      setUnlimitedPlusLines(0);
      setUnlimitedPremiumLines(0);
      setUnlimitedType(-1);
      setGigLines(0);
      setGigDataUsage(-1);
      setByodLines(0);
      setDeviceLines(0);
      setDevice([defaultDeviceSelection]);
      setPaymentMethod("ONETIME");
      setCms(0);
      setCsp("-1");
      setLastQuoteSavedDate("");
      setCart(new CartModel(null, null, null));
      setShowLoader(false);
      setPortTn(0);
      setTns([]);
      setImei([]);
      setToggleSection(new Toggle(true,true,true,true));
    })
    .catch(err => {
      if (err?.response?.status === 401) {
        navigate("/unauthorize");
      }
      setShowLoader(false);
      showDialog();
      setErrorMessage('Unable to clear cart. Please try again.');      
    })
  };

  const updateCart = () => {
    //show spinner
    setShowLoader(true);
    const validationList = validateConfig();
    let isValid = validationList
      .map((x) => x.isValid)
      .reduce((a, b) => a && b, true);

    if (isValid) {
      if (!isValidAddress) {
        validateAddressAsync();
      }
      else {
        submitFinalQuote();
      }
    } else {
      //hide spinner
      setShowLoader(false);
      const firstFailedRule = validationList.filter(x => x.category !== 'CONTACT-INFO').find((x) => !x.isValid);
      showDialog();
      const contactInfoRequiredList = validationList?.filter(x => x.category === 'CONTACT-INFO' && x.requiredFieldCheck)?.map(x => x.errMsg);
      const contactInfoValidationList = validationList?.filter(x => x.category === 'CONTACT-INFO' && !x.requiredFieldCheck)?.find((x) => !x.isValid)?.errMsg || '';
      let contactInfoFailedRule = '';

      if(contactInfoRequiredList.length > 0) {

        contactInfoFailedRule = 'Please ensure all required fields are populated in Contact Information.';

        if(isContactInfoEdited) {
          contactInfoFailedRule = 'Please ensure all required fields are populated.';
        }


      } else {
        contactInfoFailedRule = contactInfoValidationList;
      }

      // if (firstFailedRule.rule === ERRORS.ERR_NON_ZERO_LINES_CHECK.RULE_ID) {
      //   setErrorMessage(ERRORS.ERR_NON_ZERO_LINES_CHECK.ERR_MSG);
      // } 
      
      if (contactInfoFailedRule !== '') {
        setErrorMessage(contactInfoFailedRule);
      }
      else if (firstFailedRule.rule === ERRORS.ERR_NO_SELECTION_CHECK.RULE_ID) {
        setErrorMessage(ERRORS.ERR_NO_SELECTION_CHECK.ERR_MSG);
      }
      else if (firstFailedRule.rule === "TotalNumberOfLines") {
        setErrorMessage(`Total lines for Unlimited and By the Gig cannot exceed ${totalLines}. Please revise the number of lines, then click UPDATE CART.`);
      }
      else if (firstFailedRule.rule === "TotalNumberOfUnlimitedIntroLines") {
        setErrorMessage(`Total lines for Unlimited Intro and By the Gig cannot exceed ${totalLines}. Please revise the number of lines, then click UPDATE CART.`);
      } 
      else if (firstFailedRule.rule === ERRORS.ERR_NO_UNLIMITEDLINES_SELECTION_CHECK.RULE_ID) {
        setErrorMessage(ERRORS.ERR_NO_UNLIMITEDLINES_SELECTION_CHECK.ERR_MSG);
      }
      else if (firstFailedRule.rule === ERRORS.ERR_NO_UNLIMITEDINTROLINES_SELECTION_CHECK.RULE_ID) {
        setErrorMessage(ERRORS.ERR_NO_UNLIMITEDINTROLINES_SELECTION_CHECK.ERR_MSG);
      }
      else if (firstFailedRule.rule === ERRORS.ERR_UNLIMITEDINTRO_MATCH_PLUS_PREMIUM_CHECK.RULE_ID) {
        setErrorMessage(ERRORS.ERR_UNLIMITEDINTRO_MATCH_PLUS_PREMIUM_CHECK.ERR_MSG);
      }
      else if (firstFailedRule.rule === "LinesMatchDevices") {
        setErrorMessage('Total number of Lines does not match the number of Devices. Please revise the number of Lines and Devices, then click UPDATE CART.');
      } else if (firstFailedRule.rule === "GigDataUsageNotChosen") {
        setErrorMessage('Please select an option for By the Gig - Shared Data.');
      } else if (firstFailedRule.rule === "GigUnitsNotChosen") {
        setErrorMessage('Please specify the number of phone lines for By the Gig - Shared Data.');
      } else if (firstFailedRule.rule === ERRORS.ERR_PORT_TN_LIMIT_CHECK.RULE_ID) {
        setErrorMessage(ERRORS.ERR_PORT_TN_LIMIT_CHECK.ERR_MSG);
      } else if (firstFailedRule.rule === ERRORS.ERR_TN_IMEI_VALIDATION_COMPLETION_CHECK.RULE_ID) {
        setErrorMessage(ERRORS.ERR_TN_IMEI_VALIDATION_COMPLETION_CHECK.ERR_MSG);
      } else if (firstFailedRule.rule === "DeviceSelectionNotMade") {
        setErrorMessage('Please select a phone device associated to the number of phone devices.');
      } else if (firstFailedRule.rule === "UnitsSelectionNotMade") {
        setErrorMessage('Please specify the number of phone devices.');
      }
    }
  };

  // COMMENTED: SEND TO CUSTOMER
  const emailQQToCustomer = () => {
    setShowLoader(true);
    SendQQToCustomer(customerInformation.opportunityNumber,qqSession.opportunityId, customerContactEmail,qqSession.agentId).then(response => { 
     
      if(response.result.toUpperCase() === emailSuccessStatus) {
          setEmailResultStatus("Sent to Customer on " + response?.data?.sentDate);
          showDialog();
          setIsSuccess(true);
          setSuccessMessage(SUCCESS.SUCCESS_EMAIL_SENT_TO_CUSTOMER.SUCCESS_MSG);
          setShowLoader(false);
          setshowResendQuickQuote(true);
      } else if(response.result.toUpperCase() === emailNotSentStatus || response.result.toUpperCase() === emailExhaustedStatus) {
          setShowLoader(false);
          showDialog();
          setErrorMessage(response.errorMessage); 
      } else {
          setShowLoader(false);
          showDialog();
          setErrorMessage(ERRORS.ERR_EMAIL_EXCEPTION.ERR_MSG); 
      }
    }).catch(err => {
          if (err?.response?.status === 401) {
            navigate("/unauthorize");
          }
          setShowLoader(false);
          showDialog();
          setErrorMessage(ERRORS.ERR_EMAIL_EXCEPTION.ERR_MSG);      
    });
  };

  //Commented out for future use
  // const prepareCustomerInfoErrorMsg = (errList) => {
  //   return (
  //     errList.map(x => (<>{x}<br/></>))
  //   );
  // }
  
  const validateConfig = () => {
    let lines = unlimitedLines + gigLines;
    let introLines = unlimitedIntroLines + gigLines;
    
    let devices =
      byodLines +
      (device.length > 0
        ? device.map((x) => x.units).reduce((a, b) => a + b, 0)
        : 0);

    let validationList = [];
    let totalSelection = lines + devices;

    //Validate whether there are some units chosen
    // if (ERRORS.ERR_NON_ZERO_LINES_CHECK.VALIDATOR(lines)) {
    //   validationList.push({
    //     rule: ERRORS.ERR_NON_ZERO_LINES_CHECK.RULE_ID,
    //     isValid: false,
    //   });
    // }

    //Validate whether there are some units chosen
    let deviceSelected = device.filter(x => !!x.deviceDetail);

    if(ERRORS.ERR_CONTACT_INFORMATION_NAME_CHECK.VALIDATOR(customerContactInformation.name || "")) {
      validationList.push({
        rule: ERRORS.ERR_CONTACT_INFORMATION_NAME_CHECK.RULE_ID,
        isValid: false,
        category: 'CONTACT-INFO',
        errMsg: ERRORS.ERR_CONTACT_INFORMATION_NAME_CHECK.ERR_MSG,
        requiredFieldCheck: true 
      });
    }

    if(ERRORS.ERR_CONTACT_INFORMATION_BUSINESS_NAME_CHECK.VALIDATOR(customerContactInformation.businessName || "")) {
      validationList.push({
        rule: ERRORS.ERR_CONTACT_INFORMATION_BUSINESS_NAME_CHECK.RULE_ID,
        isValid: false,
        category: 'CONTACT-INFO',
        errMsg: ERRORS.ERR_CONTACT_INFORMATION_BUSINESS_NAME_CHECK.ERR_MSG,
        requiredFieldCheck: true
      });
    }

    if(ERRORS.ERR_CONTACT_INFORMATION_ADDRESS_1_CHECK.VALIDATOR(customerContactInformation.address1 || "")) {
      validationList.push({
        rule: ERRORS.ERR_CONTACT_INFORMATION_ADDRESS_1_CHECK.RULE_ID,
        isValid: false,
        category: 'CONTACT-INFO',
        errMsg: ERRORS.ERR_CONTACT_INFORMATION_ADDRESS_1_CHECK.ERR_MSG,
        requiredFieldCheck: true
      });
    }

    if(ERRORS.ERR_CONTACT_INFORMATION_CITY_CHECK.VALIDATOR(customerContactInformation.city || "")) {
      validationList.push({
        rule: ERRORS.ERR_CONTACT_INFORMATION_CITY_CHECK.RULE_ID,
        isValid: false,
        category: 'CONTACT-INFO',
        errMsg: ERRORS.ERR_CONTACT_INFORMATION_CITY_CHECK.ERR_MSG,
        requiredFieldCheck: true
      });
    }

    if(ERRORS.ERR_CONTACT_INFORMATION_STATE_CHECK.VALIDATOR(customerContactInformation.state || "")) {
      validationList.push({
        rule: ERRORS.ERR_CONTACT_INFORMATION_STATE_CHECK.RULE_ID,
        isValid: false,
        category: 'CONTACT-INFO',
        errMsg: ERRORS.ERR_CONTACT_INFORMATION_STATE_CHECK.ERR_MSG,
        requiredFieldCheck: true
      });
    }

    if(ERRORS.ERR_CONTACT_INFORMATION_STATE_LENGTH_CHECK.VALIDATOR(customerContactInformation.state || "")) {
      validationList.push({
        rule: ERRORS.ERR_CONTACT_INFORMATION_STATE_LENGTH_CHECK.RULE_ID,
        isValid: false,
        category: 'CONTACT-INFO',
        errMsg: ERRORS.ERR_CONTACT_INFORMATION_STATE_LENGTH_CHECK.ERR_MSG
      });
    }

    if(ERRORS.ERR_CONTACT_INFORMATION_EMAIL_REQUIRED_CHECK.VALIDATOR(customerContactInformation.email || "")) {
      validationList.push({
        rule: ERRORS.ERR_CONTACT_INFORMATION_EMAIL_REQUIRED_CHECK.RULE_ID,
        isValid: false,
        category: 'CONTACT-INFO',
        errMsg: ERRORS.ERR_CONTACT_INFORMATION_EMAIL_REQUIRED_CHECK.ERR_MSG,
        requiredFieldCheck: true
      });
    }

    if(ERRORS.ERR_CONTACT_INFORMATION_EMAIL_CHECK.VALIDATOR(customerContactInformation.email || "")) {
      validationList.push({
        rule: ERRORS.ERR_CONTACT_INFORMATION_EMAIL_CHECK.RULE_ID,
        isValid: false,
        category: 'CONTACT-INFO',
        errMsg: ERRORS.ERR_CONTACT_INFORMATION_EMAIL_CHECK.ERR_MSG,
      });
    }

    if(ERRORS.ERR_CONTACT_INFORMATION_ZIP_CHECK.VALIDATOR(customerContactInformation.zip || "")) {
      validationList.push({
        rule: ERRORS.ERR_CONTACT_INFORMATION_ZIP_CHECK.RULE_ID,
        isValid: false,
        category: 'CONTACT-INFO',
        errMsg: ERRORS.ERR_CONTACT_INFORMATION_ZIP_CHECK.ERR_MSG,
        requiredFieldCheck: true
      });
    }

    if(ERRORS.ERR_CONTACT_INFORMATION_ZIP_LENGTH_CHECK.VALIDATOR(customerContactInformation.zip || "")) {
      validationList.push({
        rule: ERRORS.ERR_CONTACT_INFORMATION_ZIP_LENGTH_CHECK.RULE_ID,
        isValid: false,
        category: 'CONTACT-INFO',
        errMsg: ERRORS.ERR_CONTACT_INFORMATION_ZIP_LENGTH_CHECK.ERR_MSG
      });
    }

    if(ERRORS.ERR_CONTACT_INFORMATION_PHONE_NO_CHECK.VALIDATOR(customerContactInformation.phoneNumber?.value || "")) {
      validationList.push({
        rule: ERRORS.ERR_CONTACT_INFORMATION_PHONE_NO_CHECK.RULE_ID,
        isValid: false,
        category: 'CONTACT-INFO',
        errMsg: ERRORS.ERR_CONTACT_INFORMATION_PHONE_NO_CHECK.ERR_MSG
      });
    }

    if(ERRORS.ERR_CONTACT_INFORMATION_PHONE_NO_NULL_CHECK.VALIDATOR(customerContactInformation.phoneNumber?.value || "")) {
      validationList.push({
        rule: ERRORS.ERR_CONTACT_INFORMATION_PHONE_NO_NULL_CHECK.RULE_ID,
        isValid: false,
        category: 'CONTACT-INFO',
        errMsg: ERRORS.ERR_CONTACT_INFORMATION_PHONE_NO_NULL_CHECK.ERR_MSG,
        requiredFieldCheck: true
      });
    }
    
    if (ERRORS.ERR_NO_SELECTION_CHECK.VALIDATOR(totalSelection, gigDataUsage, deviceSelected, unlimitedType)) {
      validationList.push({
        rule: ERRORS.ERR_NO_SELECTION_CHECK.RULE_ID,
        isValid: false,
      });
    }

    //Validate whether the total number of lines is <= 10
    //Validate whether there are some units chosen
    if (lines > totalLines) {
      validationList.push({
        rule: "TotalNumberOfLines",
        isValid: false,
      });
    }

    // Validate whether total numbers of Unlimited Intro and By the Gig <=20
    if (introLines > totalLines) {
      validationList.push({
        rule: "TotalNumberOfUnlimitedIntroLines",
        isValid: false,
      });
    }

    //Validate if Unlimited Intro is selected for "Which Unlimited Plan?" and no quantity has been selected for "How many Unlimited Intro lines?
    if (ERRORS.ERR_NO_UNLIMITEDINTROLINES_SELECTION_CHECK.VALIDATOR(unlimitedType, unlimitedIntroLines)) {
      validationList.push({
        rule: ERRORS.ERR_NO_UNLIMITEDINTROLINES_SELECTION_CHECK.RULE_ID,
        isValid: false,
      });
    }
         
    //Validate if Unlimited is selected for "Which Unlimited Plan?" and no quantity has been selected for "How many Unlimited lines?
    if (ERRORS.ERR_NO_UNLIMITEDLINES_SELECTION_CHECK.VALIDATOR(unlimitedType, unlimitedLines)) {
      validationList.push({
        rule: ERRORS.ERR_NO_UNLIMITEDLINES_SELECTION_CHECK.RULE_ID,
        isValid: false,
      });
    }

    //Validation whether the number of lines for Unlimited Plus AND Unlimited Premium cannot exceed the number selected for Unlimited Intro lines. 
    if (ERRORS.ERR_UNLIMITEDINTRO_MATCH_PLUS_PREMIUM_CHECK.VALIDATOR(unlimitedIntroLines,unlimitedPlusLines,unlimitedPremiumLines)) {
      validationList.push({
        rule: ERRORS.ERR_UNLIMITEDINTRO_MATCH_PLUS_PREMIUM_CHECK.RULE_ID,
        isValid: false,
      });
    }

    //Validate whether the number of lines (Unlimited + Gig or UnlimitedIntro + Gig ) and devices match each other
    if (( (unlimitedType === -1 || unlimitedType === unlimitedExistingType) && lines !== devices) || ( unlimitedType === unlimitedIntroType && introLines !== devices) ) {
      validationList.push({
        rule: "LinesMatchDevices",
        isValid: false,
      });
    }

    //Validate whether the units is chosen and the gig data usage is not chosen    
    if(gigLines > 0 && gigDataUsage === -1) {
      validationList.push({
        rule: "GigDataUsageNotChosen",
        isValid: false,
      });
    }

    //Validate whether the gig data usage is chosen when the number of units is greater than zero    
    if(gigLines === 0 && gigDataUsage !== -1) {
      validationList.push({
        rule: "GigUnitsNotChosen",
        isValid: false,
      });
    }

    //DOUBLE CHECK: This check is already validated in validate button. 
    //Assume all the phone numbers are validated
    //User quits the app
    //User relaunches the app
    //User tries to reduce the lines to a number which is below the total number of phone numbers to be ported and then without hitting on validate
    //he hits on update cart, then we have to prevent him from saving as his portTn > totalLines    
    const totalNumberOfLines = unlimitedType === unlimitedIntroType ? introLines : lines;
    if(ERRORS.ERR_PORT_TN_LIMIT_CHECK.VALIDATOR(portTn, totalNumberOfLines)) {
      validationList.push({
        rule: ERRORS.ERR_PORT_TN_LIMIT_CHECK.RULE_ID,
        isValid: false,
      });
    }

    //Validate whether all the phone numbers are validated
    if(ERRORS.ERR_TN_IMEI_VALIDATION_COMPLETION_CHECK.VALIDATOR(tns, imei)) {
      validationList.push({
        rule: ERRORS.ERR_TN_IMEI_VALIDATION_COMPLETION_CHECK.RULE_ID,
        isValid: false,
      });
    }

    //Validate whether the user has chosen payment options for all devices -- we are handling this by defaulting the payment options to pay in full
    //Validate whether the user has chosen a non-zero unit value if he has chosen any device
    if (device.length === 1) {
      //checking if this is the default row with no selections made
      const [firstDevice] = device;
      if (!(!firstDevice.deviceDetail && firstDevice.payment === 'ONETIME' && firstDevice.units === 0)) {
        //if it is not the default row then perform the below validations
        const isDeviceValid = device.filter(device => !device.deviceDetail).length === 0;      
        if (!isDeviceValid) {
          validationList.push({
            rule: "DeviceSelectionNotMade",
            isValid: false,
          });
        } else {
          const isUnitsValid = device.filter(device => device.units === 0).length === 0;
          if (!isUnitsValid) {
            validationList.push({
              rule: "UnitsSelectionNotMade",
              isValid: false,
            });
          }
        }
      } 
    } else {
      //if it is not the default row then perform the below validations
      const isDeviceValid = device.filter(device => !device.deviceDetail).length === 0;      
      if (!isDeviceValid) {
        validationList.push({
          rule: "DeviceSelectionNotMade",
          isValid: false,
        });
      } else {
        const isUnitsValid = device.filter(device => device.units === 0).length === 0;
        if (!isUnitsValid) {
          validationList.push({
            rule: "UnitsSelectionNotMade",
            isValid: false,
          });
        }
      }
    }
    
    return validationList;
  };

  
  const submitFinalQuote = (isScrubbedAddress = false) => {
    let finalQuote = null;

    prepareFinalQuote().then((response) => {
      finalQuote = response;
      //form the whole payload that needs to be sent to the back end
      //DEV-PURPOSE
      let finalPostPayload = postPayload;
      //replacting the cart
      finalPostPayload.previousQuote = finalQuote;

      //COMMENT: SAVE CustomerContact all time 
      // let finalContactInfo = null;
      // if (isContactInfoEdited) {
      //   finalContactInfo = JSON.stringify(customerContactInformation);
      // }
      let updatedCustomerContactInformation = {
        ...customerContactInformation,
        "isValidAddress" : isValidAddress
      };

      if(isScrubbedAddress)
      {
        updatedCustomerContactInformation = {
          ...customerContactInformation,
          ...validAddress,
          "isValidAddress" : true
        };
      }

      //sending only the previous quote
      SaveConfigCart({opportunityId: finalQuote.opportunityId, previousQuote: JSON.stringify(finalQuote), customerContactInfo: JSON.stringify(updatedCustomerContactInformation), agentId: qqSession.agentId, lastModifiedUser: qqSession.agentId})
      .then((response) => {
        if(SUCCESS.SUCCESS_UPDATED_CART.VALIDATOR(response)) {
          setLastQuoteSavedDate(response.responseData.lastQuoteSaved);
          //save the data through a post request to the back end
          //on success set the cart      
          setCart(finalQuote.cart);
          setShowLoader(false);
          showDialog();
          setIsSuccess(true);
          setSuccessMessage(SUCCESS.SUCCESS_UPDATED_CART.SUCCESS_MSG);
          // COMMENTED: SEND TO CUSTOMER
          setcustomerContactEmail(customerContactInformation.email);
          if(!!customerContactInformation.email) {
            (async () => {
              const sendToCustomerEmailResponse = await GetSendQQToCustomerDetails(qqSession.opportunityId, customerContactInformation.email,qqSession.agentId);
              setSendToCustomerInfo(sendToCustomerEmailResponse);
            })(); 
          }
          setContactInfoEdited(false);
        } else {
          setShowLoader(false);
          showDialog();
          setErrorMessage('Data was unable to save. Please try again.');        
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          navigate("/unauthorize");
        }
        setShowLoader(false);
        showDialog();
        setErrorMessage('Data was unable to save. Please try again.');
      });
    }, (err) => {
      if (err?.response?.status === 401) {
        navigate("/unauthorize");
      }
      setShowLoader(false);
      showDialog();
      setErrorMessage('Data was unable to save. Please try again.');   
    });
  };

  const findUnlimitedComboList = (totalUnlimitedLines, gigLines) => {
    let unlimitedComboList = [];
    for (let i = gigLines + 1; i <= totalUnlimitedLines; i++) {
      let unlimitedCombo = unlimitedComboLinesList.find(x => x.id === i);
      unlimitedComboList.push({
        id: unlimitedCombo.id,
        unitPrice: unlimitedCombo.unitPrice
      });
    }

     return unlimitedComboList;
  }
  const findPrice = (factor, id) => {
    let price = null;
    switch(factor){
      case "unlimited": {
        price = unlimitedLinesList.find(x => x.id === id).unitPrice;
        break;
      }
      case "unlimitedIntro": {
        price = unlimitedIntroLinesList.find(x => x.id === id).unitPrice;
        break;
      }
      case "gig": {
        price = gigDataUsageList.find(x => x.id === id).unitPrice;
        break;
      }
      default: 
        break;
    }
    return price;
  };
  
  const prepareFinalQuote = async () => {
    //opp id
    const opportunityId = qqSession.opportunityId;//'opp-123';

    //UnlimitedType
    const unlimitedPlan = unlimitedType;

    //unlimited
    const unlimitedLinePrice = findPrice("unlimited", unlimitedLines);
    const unlimited = new Unlimited(unlimitedLines, unlimitedLinePrice);

    //unlimited Intro
    const unlimitedIntroLinePrice = findPrice("unlimitedIntro", unlimitedIntroLines);
    let unlimitedIntro = new UnlimitedIntro(unlimitedIntroLines, unlimitedIntroLinePrice);
    
    //unlimited Combo
    if(gigLines > 0 && unlimitedIntroLines > 0)
    {
        const totalUnlimitedLines = gigLines + unlimitedIntroLines;

        let unlimitedComboList = findUnlimitedComboList(totalUnlimitedLines, gigLines);
        unlimitedIntro = new UnlimitedIntro(unlimitedIntroLines, unlimitedIntroLinePrice, unlimitedComboList);
    }

    //unlimited Plus
    const unlimitedPlus = new UnlimitedPlus(unlimitedPlusLines, unlimitedPlusLinesUnitPrice);

    //unlimited Premium 
    const unlimitedPremium = new UnlimitedPremium(unlimitedPremiumLines, unlimitedPremiumLinesUnitPrice);

    //byTheGig
    const gigDataUsagePrice = findPrice("gig", gigDataUsage);
    const byTheGig = new ByTheGig(gigDataUsage, gigLines, gigDataUsagePrice);
    //tns
    const tnsList = new PortTn(portTn, tns);
    //byod
    const byod = new Byod(byodLines);

    //imei
    const imeiList = new PortImei(imei);

    //devices
    //below checks whether the device section has only a default row, hence ignoring it while saving device details
    const defaultRowCheck = device.filter(x => !x.deviceDetail).length === 1;
    let devices = [];
    //save only if any device selections are made
    if (device.length > 0 && !defaultRowCheck) {
      devices = device;
    }
    //savings
    const savings = new Savings(+cms, csp);

    //Frontend / Backend calculation
    const finalQuote = (cartCalcDecider === "FE") ? quoteCalculationFrontEnd(prepareCartItems, cms, opportunityId, unlimited, byTheGig, tnsList, byod, imeiList, devices, savings) : 
    await quoteCalculationBackend(opportunityId, unlimitedPlan, unlimited, unlimitedIntro, unlimitedPlus, unlimitedPremium, byTheGig, tnsList, byod, imeiList, devices, savings);

    return finalQuote;  
  };

  const prepareCartItems = () => {
    let cartItems = [];
    let cartIdCounter = 0;

    //Unlimited lines
    if(unlimitedLines > 0) {
      const mrcPrice = +unlimitedLinesList.find(x => x.id === unlimitedLines).unitPrice;

      cartItems.push(
        new CartItem(
          cartIdCounter,
          cartItemDetails.Unlimited.category,
          cartItemDetails.Unlimited.name,
          unlimitedLines,
          mrcPrice,//(unlimitedLines * 50) / 12,
          0,
          mrcPrice,
          "",
          ""
        )
      );
    }

    //Gig lines
    if(gigLines > 0) {
      const gigMrcPrice = +gigDataUsageList.find(x => x.id === gigDataUsage).unitPrice;
      
      cartItems.push(
        new CartItem(
          ++cartIdCounter, 
          cartItemDetails.Gig.category, 
          gigDataUsageList.find(x => x.id === gigDataUsage).name + ' Shared', 
          gigLines, 
          gigMrcPrice,//(gigLines * 10) / 12, 
          0,
          gigMrcPrice,
          "",
          ""
        )
      );
    }

    //Bring your own device
    if(byodLines > 0) {
      cartItems.push(
        new CartItem(
          ++cartIdCounter,
          cartItemDetails.Byod.category,
          cartItemDetails.Byod.name,
          byodLines,
          0,
          0,
          0,
          "",
          ""
        )
      );
    }

    //Device
    const deviceCount = device.map(x => x.units).reduce((a, b) => a + b);
    if(deviceCount > 0) {
      device.map((device) => {     
        let itemExistsinCart = false;
      
        if (device.payment === "ONETIME") {
          const nrcUnitPrice = device.units * deviceList.find(x => x.productCode === device.deviceDetail.id).paymentOptions.find(x => x.id === "ONETIME").price;
          itemExistsinCart = groupDevice(cartItems, device, nrcUnitPrice, itemExistsinCart, true);

          if (!itemExistsinCart) {
            //monthly
            cartItems.push(
              new CartItem(
                ++cartIdCounter,
                cartItemDetails.PcatDevices.category,
                device.deviceDetail.name,
                device.units,
                0,
                nrcUnitPrice, //device.units * 500
                device.unitPrice,
                device.deviceDetail.id,
                device.payment
              )
            );
          }
        } else {
          //recurring
          const recurringUnitPrice = deviceList.find(x => x.productCode === device.deviceDetail.id).paymentOptions.find(x => x.id === "RECURRING").price;
          const singleUnitPrice = deviceList.find(x => x.productCode === device.deviceDetail.id).paymentOptions.find(x => x.id === "ONETIME").price;
          const mrcUnitPrice = device.units * recurringUnitPrice;

          itemExistsinCart = groupDevice(cartItems, device, mrcUnitPrice, itemExistsinCart, false);

          if(!itemExistsinCart) {
            cartItems.push(
              new CartItem(
                ++cartIdCounter,
                cartItemDetails.PcatDevices.category,
                device.deviceDetail.name,
                device.units,
                mrcUnitPrice,//(device.units * 500) / 12,
                0,
                singleUnitPrice,
                device.deviceDetail.id,
                device.payment
              )
            );
          }
        }
        return device;
      });
    }

    return cartItems;
  };

  function quoteCalculationFrontEnd(prepareCartItems, cms, opportunityId, unlimited, byTheGig, tnsList, byod, imeiList, devices, savings) {
    const cartItems = prepareCartItems();
    const totalMrc = cartItems.length > 0
      ? cartItems.map((x) => x.mrc).reduce((a, b) => a + b, 0)
      : 0;
    const totalNrc = cartItems.length > 0
      ? cartItems.map((x) => x.nrc).reduce((a, b) => a + b, 0)
      : 0;
  
    const totalMrcExcludingDeviceMrc = cartItems.length > 0 ? (cartItems.filter(x => x.category === "lines").map((x) => x.mrc).reduce((a, b) => a + b, 0)) : 0;
    const monthlySavings = +cms > totalMrcExcludingDeviceMrc ? +cms - totalMrcExcludingDeviceMrc : 0;
    const yearlySavings = monthlySavings * 12;
    const cart = new CartModel(cartItems, totalMrc, totalNrc, monthlySavings, yearlySavings);
    const finalQuote = new FinalQuote(opportunityId, unlimited, byTheGig, tnsList, byod, imeiList, devices, savings, cart);
    return finalQuote;
  }
  
//Postal Scrubbing
  const validateAddressAsync = () => {
    setValidationSpinner(true);
    const payload = {
      addressLine1: customerContactInformation.address1,
      addressLine2: customerContactInformation.address2,
      addressLine3: "",
      city: customerContactInformation.city,
      state: customerContactInformation.state,
      zipCode: customerContactInformation.zip
    };
    ValidateAddress(payload)
      .then(response => {
        if (response.responseStatus) {
          if (response?.responseData?.httpStatus === 200) {
            setValidAddressList(response?.responseData?.data);
            showPopupDialog();
          } else {
            setValidationSpinner(false);
            setShowLoader(true);
            submitFinalQuote();
          }
        }
        setValidationSpinner(false);
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          navigate("/unauthorize");
        }
        // Suppress the error to Proceed with cart update
        setValidationSpinner(false);
        setShowLoader(true);
        submitFinalQuote();
      });
  };

  async function quoteCalculationBackend(opportunityId, unlimitedType, unlimited, unlimitedIntro, unlimitedPlus, unlimitedPremium, byTheGig, tnsList, byod, imeiList, devices, savings) {
    let finalQuote = null;
    const payload = JSON.stringify({
      opportunityId, unlimitedType, unlimited, unlimitedIntro, unlimitedPlus, unlimitedPremium, byTheGig, tnsList, byod, imeiList, devices, savings
    });
    
    const result = await CalculateCart({ previousQuote: payload });
    const cartItems = result.cartItems;
    const totalMrc = result.totalMrc;
    const totalNrc = result.totalNrc;
    const monthlySavings = result.monthlySavings;
    const yearlySavings = result.yearlySavings;
    const cart = new CartModel(cartItems, totalMrc, totalNrc, monthlySavings, yearlySavings);
    finalQuote = new FinalQuote(opportunityId, unlimitedType, unlimited, unlimitedIntro, unlimitedPlus, unlimitedPremium, byTheGig, tnsList, byod, imeiList, devices, savings, cart);
    return finalQuote;
  }
  
  const groupDevice = (cartItems, device, amount, itemExistsinCart, isOneTimePrice) => {
    cartItems.map(item => {
      if (item.category === "devices" && item.productCode === device.deviceDetail.id && item.paymentType === device?.payment) {
        item.units = item.units + device.units;
        (isOneTimePrice) ? item.nrc = item.nrc + amount : item.mrc = item.mrc + amount;
        itemExistsinCart = true;
      }
  
      return item;
    });
    return itemExistsinCart;
  }

  const setAddress = () => {
    setContactInformation({
      ...customerContactInformation,
      ...validAddress
    });
    setIsValidAddress(true);
  };

  const showErrorMessage = () => {
    return (
            <AlertDialog 
              open={open} 
              content={errorMessage} 
              setIsValid={setIsValid} 
              setOpen={setOpen} 
              setErrorMessage={setErrorMessage} 
              isSuccess={isSuccess}
              setIsSuccess={setIsSuccess}
              successMessage={successMessage}
              setSuccessMessage={setSuccessMessage}
              isPrevQuoteError={prevQuoteRefresh} 
              setPrevQuoteRefresh={setPrevQuoteRefresh} 
              clearCart={clearCart} 
              hideDialog={hideDialog}
            />
           );
  }

  const showNotifyMessage = () => {
    return (
            <Notification
              open={notify}
              hideNotificationDialog={hideNotificationDialog}
              notificationMessage={notificationMessage}
            />
           );
  }

  const showPopupMessage = () => {
    return (
            <ScrubbedAddressPopup
              open={popup} 
              validAddress={validAddress}
              setValidAddress={setValidAddress}
              setValidAddressList={setValidAddressList}
              validAddressList={validAddressList}
              hideDialog={hidePopupDialog}
              setAddress={setAddress}
              isContactInfoEdited={isContactInfoEdited}
              setContactInfoEdited={setContactInfoEdited}
              submitFinalQuote={submitFinalQuote}
              setShowLoader={setShowLoader}
              btnApplyDisabled= {btnApplyDisabled}
              setBtnApplyDisabled={setBtnApplyDisabled}
            />
           );
  }
  //#endregion
  return (
    <>
      <QuoteContainer>
        {/* {showBanner && !showLoader ? <Banner bannerText={bannerText} showBanner={showBanner} setShowBanner={setShowBanner}></Banner> : null}         */}
        {showLoader ? <Loader showValidationSpinner={showValidationSpinner}/> : null}
        <Header updateCart={updateCart} customerInformation={customerInformation} uiSession = {qqSession} lastQuoteSavedDate={lastQuoteSavedDate} setShowLoader={setShowLoader} />
        <QQConfigure>
          <ConfigureSection>
            <Configuration configProps={configProps} updateCart={updateCart} />
          </ConfigureSection>
          <section></section>
          <CartSection>
            <Cart cart={cart} clearCart={clearCart} />
            {/* COMMENTED: SEND TO CUSTOMER */}
            <SendEmailToCustomer  customerEmail={customerContactEmail} emailResultStatus={emailResultStatus} emailQQToCustomer={emailQQToCustomer} showResendQuickQuote={showResendQuickQuote}/>
          </CartSection>
        </QQConfigure>
        {notify && showNotifyMessage() }
        {showPopupMessage()}
        {!isValid ? showErrorMessage() : null}
        
      </QuoteContainer>
      {/* <Footer/> */}
    </>    
  );
};

//#region styled-components
const QQConfigure = styled.div`
  display: grid;
  grid-template-columns: 1.25fr .07fr 1fr;
  padding:10px;
`;

const QuoteContainer = styled.div`
  padding: 0 20px 0 20px;
`;

const ConfigureSection = styled.section`
`;
const CartSection = styled.section`
  display: flex;
  flex-direction:column;
`;
//#endregion

export default Quote;
