export class Cart {
    constructor(cartItems, totalMrc, totalNrc, monthlySavings = 0, yearlySavings = 0) {
        this.cartItems = cartItems;
        this.totalMrc = totalMrc;
        this.totalNrc = totalNrc;
        this.monthlySavings = monthlySavings;
        this.yearlySavings = yearlySavings;
    }
}

export class CartItem {
    constructor(id, category, name, units, mrc, nrc, unitPrice, productCode, paymentType) {
        this.id = id;
        this.category = category;
        this.name = name;
        this.units = units;
        this.mrc = mrc;
        this.nrc = nrc;
        this.unitPrice = unitPrice;
        this.productCode = productCode;
        this.paymentType = paymentType;
    }
}