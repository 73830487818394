import React from "react";
import styled from 'styled-components';
import { GoAlert } from 'react-icons/go';
import { ERRORS } from '../../constants/errors';

const UnAuthorize = () => {
  return (
    <StyledContainer>
      <StyledAlertIcon />
      <h1>Unauthorized</h1>
      <StyledUnAuthorize>{ERRORS.UNAUTHORIZED_MESSAGE.ERR_MSG}</StyledUnAuthorize>
    </StyledContainer>
  );    
};

export default UnAuthorize;


const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 90px;
  align-items: center;

  h1 {
    font-size: 3rem;
  }
`
const StyledUnAuthorize = styled.h3`
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0,0,0,.85);
  font-weight: 500;
}
`
const StyledAlertIcon = styled(GoAlert)`
  fill: rgb(209, 19, 20);
  font-size: 7rem;
`