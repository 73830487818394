import { GET_TOOLTIPS, GET_QUOTE } from "../constants/urls";
import axios from "axios";

export const GetToolTips = async () => {
    try {
        const {data: res} = await axios.get(GET_TOOLTIPS);
        if(res.status === 200) {
            return res;
        } 
        return res;
    } catch(err) {
        return Promise.reject(err);
    }
};

//save configurations
//get configurations
export const GetPcatResponse = async (opportunityId) => {
    try {
        const res = await axios.get(GET_QUOTE + `/${opportunityId}`);
        if(res.status === 200) {
            return res.data;
        } 
        return res;
    } catch(err) {
        return Promise.reject(err);
    }
};
