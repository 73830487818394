import axios from "axios";
import { GET_QQ_SESSION_DATA } from "../constants/urls";

//get QuickQuote Session

export const GetQuickQuoteSession = async (correlationIdParams) => {
    try {
        const res = await axios.post(GET_QQ_SESSION_DATA, {
            correlationId: correlationIdParams,
          });
        if(res.status === 200) {
            return res.data;
        } 
        return res;
    } catch(err) {
        return Promise.reject(err);
    }
};