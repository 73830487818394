import React from "react";
import PropType from "prop-types";
import QuickQuoteTitle from "../utility/title";
import styled from "styled-components";
import WhiteCard from "../utility/whitecard";

const Savings = ({ savingsProps }) => {
  const onClickToggleSection =()=>{
    savingsProps.setToggleSection(
      {
      ...savingsProps.toggleSection,
      savings:!savingsProps.toggleSection.savings
      }
    )
  }
  return (
    <SavingsStyles showSection={savingsProps.toggleSection.savings}>
      <QuickQuoteTitle titleName="CURRENT MONTHLY BILL" isMarginBottom  onClick={() => onClickToggleSection()} showSection={savingsProps.toggleSection.savings} showToggleBtn={true} />
      <SavingsContainer showSection={savingsProps.toggleSection.savings}>
        <WhiteCard>
          <SavingsGrid>
            <MontlyGrid>
              <DevicesLabel>Current Monthly Bill</DevicesLabel>
              <AmountInput>
                <DevicesLabel>$</DevicesLabel>
                <SavingsInput
                  value={savingsProps.cms}
                  id="cms"
                  onChange={(e) => {
                    const value = e.target.value;
                    // const regexp = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/;
                    //const regexp = /^[0-9]\d{0,9}(\.\d{1,3})?%?$/;
                    const regexp = /^((?!0)\d{1,10}|0|\.\d{1,2})($|\.$|\.\d{1,2}$)/gm;

                    if (regexp.test(value) || value === "")
                      savingsProps.setCms(value);
                  }}
                  onBlur={(e) => {
                    if (e.target.value === "") savingsProps.setCms(0);
                    else
                      savingsProps.setCms(
                        parseFloat(e.target.value).toFixed(2)
                      );
                  }}
                  onFocus={(e) => {
                    if (e.target.value === "0") savingsProps.setCms("");
                  }}
                />
              </AmountInput>
            </MontlyGrid>
            <MontlyGrid>
              <DevicesLabel>Current Mobile Provider</DevicesLabel>
              <SavingsDropdownStyle
                name="csp"
                id="csp"
                value={savingsProps.csp}
                onChange={(e) => savingsProps.setCsp(e.target.value)}
              >
                {/* <option value="-1">Select Current Provider</option> */}
                {savingsProps.cspList.map((cmp, i) => {
                  return (
                    <option key={i} value={cmp.id}>
                      {cmp.name}
                    </option>
                  );
                })}
              </SavingsDropdownStyle>
            </MontlyGrid>
          </SavingsGrid>
        </WhiteCard>
      </SavingsContainer>
    </SavingsStyles>
  );
};

//#region Savings proptypes
Savings.propType = {
  cms: PropType.number,
  setCms: PropType.func,
  csp: PropType.string,
  setCsp: PropType.func,
  cspList: PropType.array,
};
//#endregion

//#region styled-components
const SavingsInput = styled.input`
  width: 80px;
  background: linear-gradient(#000, #000) center bottom 5px / calc(100% - 10px)
    2px no-repeat;
  border: none;
  padding: 10px;
  font-size: 16px;
`;

const SavingsContainer = styled.div`
  max-height: 0;
  ${({showSection}) => showSection ? `
    max-height: 1000px;      
  `: ''}
  transition: max-height 0.15s linear; 
  overflow: hidden;
`;

const SavingsStyles = styled.div`
  ${({showSection}) => showSection ? `
    margin-top: 10px;    
  `: ''}
  padding: 0 10px 10px;
`;

const AmountInput = styled.div`
  display: grid;
  grid-template-columns: 20px auto;
`;

const MontlyGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
`;

const SavingsGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  padding: 0 20px 20px;
`;

const DevicesLabel = styled.h4`
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 5px;
`;

const SavingsDropdownStyle = styled.select`
  height: 32px;
  min-width: 220px;
  font-size: 14px;
  line-height: 1rem;
  background-position: right 8px center;
  background-size: auto;
  font-weight: 500;
  color: #000000;
  border: 2px solid #000000;
  background-color: #ffffff;
  border-radius: 0;
  background-repeat: no-repeat;
  min-width: auto;
`;
//#endregion

export default Savings;
