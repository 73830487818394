import { Device } from "../entities/devices";
import { CartItem } from "../entities/cart";

const tollFreeCodeList = ['800', '888', '877', '866', '855', '844', '833', '000'];

//UnlimitedTypes
const unlimitedIntroType = 1;
const unlimitedExistingType = 2;

//Number of lines
const linesList = Array.from({ length: 11 }, (_, i) => i);

const defaultCartItems = [
  new CartItem(1, "default", "", "", null, null, null, "", ""),
  new CartItem(2, "default", "", "", null, null, null, "", ""),
  new CartItem(3, "default", "", "", null, null, null, "", ""),
];

const defaultAdditionalOptions = [
  new CartItem(1, "default", "", "", null, null, null, "", ""),
];

//By the gig dropdown values
const byTheGigList = [
  {
    id: -1,
    name: "By the Gig - Shared Data",
    unitPrice: "",
  },
  {
    id: 1,
    name: "1 GB Shared",
    unitPrice: "15"
  },
  {
    id: 3,
    name: "3 GB Shared",
    unitPrice: "30"
  },
  {
    id: 10,
    name: "10 GB Shared",
    unitPrice: "60"
  }
];

//Devices dropdown values
const pcatDeviceList = [
  {
    productCode: "a2482-iphone-13",
    name: "Apple iPhone 13 Pro Max",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      // {
      //   id: 2,
      //   name: "RECURRING",
      //   price: "$45",
      //   term: "24",
      // },
    ],
  },
  {
    productCode: "a2482-iphone-12",
    name: "Apple iPhone 13 Pro",
    paymentOptions: [
      // {
      //   id: 1,
      //   name: "ONETIME",
      //   price: "$1000",
      //   term: "1",
      // },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },
  {
    productCode: "a2482-iphone-se",
    name: "Apple iPhone 13",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },
  {
    productCode: "iPhone-13-Mini",
    name: "Apple iPhone 13 Mini",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },
  {
    productCode: "apple-iPhone-SE",
    name: "Apple iPhone SE",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },
  {
    productCode: "apple-iPhone-12",
    name: "Apple iPhone 12",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },
  {
    productCode: "apple-iPhone-11",
    name: "Apple iPhone 11",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 100,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 50,
        term: 24,
      },
    ],
  },
  {
    productCode: "apple-iPhone-SE-2nd-Generation",
    name: "Apple iPhone SE (2nd Generation)",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },
  {
    productCode: "google-pixel-6-Pro",
    name: "Google Pixel 6 Pro",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },
  {
    productCode: "google-Pixel-6",
    name: "Google Pixel 6",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },
  {
    productCode: "motorola-moto-g-stylus-5G",
    name: "Motorola moto g stylus 5G",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },  
  {
    productCode: "motorola-one-5G-ace",
    name: "Motorola one 5G ace",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },
  {
    productCode: "motorola-moto-g-power-2022",
    name: "Motorola moto g power (2022)",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },
  {
    productCode: "motorola-moto-g-power",
    name: "Motorola moto g power",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },
  {
    productCode: "motorola-moto-g-pure",
    name: "Motorola moto g pure",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },
  {
    productCode: "samsung-galaxy-S22-Ultra",
    name: "Samsung Galaxy S22 Ultra",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },
  {
    productCode: "samsung-galaxy-S22+",
    name: "Samsung Galaxy S22+",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },
  {
    productCode: "samsung-galaxy-S22",
    name: "Samsung Galaxy S22",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },
  {
    productCode: "samsung-Galaxy-Z-Fold3-5G",
    name: "Samsung Galaxy Z Fold3 5G",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },
  {
    productCode: "samsung-Galaxy-Z-Flip3-5G",
    name: "Samsung Galaxy Z Flip3 5G",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },
  {
    productCode: "samsung-galaxy-S21-FE-5G",
    name: "Samsung Galaxy S21 FE 5G",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },
  {
    productCode: "samsung-Galaxy-A42-5G",
    name: "Samsung Galaxy A42 5G",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },
  {
    productCode: "samsung-galaxy-A13-5G",
    name: "Samsung Galaxy A13 5G",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },
  {
    productCode: "samsung-galaxy-A03s",
    name: "Samsung Galaxy A03s",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },
  {
    productCode: "Samsung-Galaxy-S2",
    name: "Samsung Galaxy S21+ 5G",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },
  {
    productCode: "Samsung-Galaxy-S21-5G",
    name: "Samsung Galaxy S21 5G",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },
  {
    productCode: "samsung-Galaxy-A52",
    name: "Samsung Galaxy A52 5G",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  },
  {
    productCode: "Samsung-Galaxy-A12",
    name: "Samsung Galaxy A12",
    paymentOptions: [
      {
        id: "ONETIME",
        name: "Pay in Full",
        price: 1000,
        term: 1,
      },
      {
        id: "RECURRING",
        name: "Pay in Monthly (24 months)",
        price: 45,
        term: 24,
      },
    ],
  }
];

//Current mobile provider dropdown values
const currentMobileProviderList = [
  {
    "id": 1,
    "name": "AT&T"
  },
  {
    "id": 2,
    "name": "AT&T Business"
  },
  {
    "id": 3,
    "name": "AT&T PrePaid"
  },
  {
    "id": 4,
    "name": "Boost Mobile"
  },
  {
    "id": 5,
    "name": "Cricket"
  },
  {
    "id": 6,
    "name": "FreedomPop"
  },
  {
    "id": 7,
    "name": "Google Fi"
  },
  {
    "id": 8,
    "name": "Google Voice"
  },
  {
    "id": 9,
    "name": "Metro PCS"
  },
  {
    "id": 10,
    "name": "Net 10"
  },
  {
    "id": 11,
    "name": "Sprint"
  },
  {
    "id": 12,
    "name": "Straight Talk"
  },
  {
    "id": 13,
    "name": "T-Mobile"
  },
  {
    "id": 14,
    "name": "T-Mobile For Business"
  },
  {
    "id": 15,
    "name": "T-Mobile Prepaid"
  },
  {
    "id": 16,
    "name": "Telcel America"
  },
  {
    "id": 17,
    "name": "TracFone"
  },
  {
    "id": 18,
    "name": "U.S. Cellular"
  },
  {
    "id": 19,
    "name": "Verizon Business"
  },
  {
    "id": 20,
    "name": "Verizon Prepaid"
  },
  {
    "id": 21,
    "name": "Verizon Wireless"
  },
  {
    "id": 22,
    "name": "Virgin Mobile"
  },
  {
    "id": 23,
    "name": "Comcast Business"
  },
  {
    "id": 24,
    "name": "Other / Not Sure"
  }
];

const toolTips = {
  ByTheGig: {
    id: "ByTheGig",
    text: "Select the amount of data usage per line for each month.",
  },
  Unlimited: {
    id: "Unlimited",
    text: "Unlimited monthly data price depends on the number of unlimited phone lines.",
  },
  Device: {
    id: "Device",
    text: "D",
  },
  Payment: {
    id: "Payment",
    text: "Select the desired payment option plan.",
  },
  Units: {
    id: "Units",
    text: "E",
  },
};

const opportunityLink = {
  SalesforceOppLink: {
    id: "SalesforceOppLink",
    value:"https://comcast--sfdce2e1--c.sandbox.vf.force.com/00674000002KS3i"

  },
};

const lastQuoteSaved = {
  lastQuoteSavedDate: {
    id: "LastQuoteSavedDate",
    value:"04/01/2022"

  },
};

const defaultPaymentDropdown = [
  {
    id: "ONETIME",
    name: "Pay in Full"
  },
  {
    id: "RECURRING",
    name: "Pay Monthly (for 24 months)"
  }
];

const defaultDeviceSelection = new Device(null, 0, "ONETIME");

const cartItemDetails = {
  Unlimited: {
    name: "Unlimited",
    category: "lines"
  },
  Gig: {
    category: "lines",    
  },
  Byod: {
    name: "Bring Your Own",
    category: "devices"
  },
  PcatDevices: {
    category: "devices"
  }
};

const defaultDeviceOption = { productCode: -1, name: 'Select Brand Device'};

const emailSentStatus = "SENT";

const emailNotSentStatus = "NOT SENT";

const emailExhaustedStatus = "EXHAUSTED";

const emailSuccessStatus = "SUCCESS";

const updateEmailInContactInfo = "Update Email in Contact Information";

const fulfilled = "FULFILLED";

export {
  linesList,
  byTheGigList,
  pcatDeviceList,
  currentMobileProviderList,
  toolTips,
  opportunityLink,
  lastQuoteSaved,
  defaultPaymentDropdown,
  defaultDeviceSelection,
  cartItemDetails,
  defaultCartItems,
  defaultAdditionalOptions,
  defaultDeviceOption,
  tollFreeCodeList,
  emailSentStatus,
  emailNotSentStatus,
  emailExhaustedStatus,
  emailSuccessStatus,
  updateEmailInContactInfo,
  fulfilled,
  unlimitedIntroType,
  unlimitedExistingType
};
