import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import QQButton from "./Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import ScrubbedAddressTable from "./scrubbedAddressTable";

export default function ScrubbedAddressPopup(props) {
  const btnDisableApply = {
    btnDisable: props.btnApplyDisabled
  };

  const btnOverRideDisable = {
    btnDisable: !props.btnApplyDisabled
  };

  const handleClose = () => {
    props.hideDialog();
    props.setShowLoader(true);
    props.submitFinalQuote();
  };

  const handleApply = () => {
    props.setAddress();
    props.hideDialog();
    props.setShowLoader(true);
    props.submitFinalQuote(true);
  };

  const handleReEdit = () => {
    props.setContactInfoEdited(true);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    props.hideDialog();
    props.setShowLoader(false);
  };

  const showAddressPopup = () => {
    return (
      <>
        <DialogTitle id="alert-dialog-title">
          SCRUBBED ADDRESS
          <CloseButton onClick={handleClose}>
            <FontAwesomeIcon icon="fa-solid fa-xmark" size="2xl" />
          </CloseButton>
        </DialogTitle>
        <ContentDiv>
          <AddressSection>
            <AddressHeader>Select and apply a valid address from the matching results.</AddressHeader>
            <br />
            <ScrubbedAddressTable setValidAddress={props.setValidAddress} validAddressList={props.validAddressList} setBtnApplyDisabled={props.setBtnApplyDisabled} />
          </AddressSection>
        </ContentDiv>
        <PostalScrubbingButtons>
          <div />
          <div />
          <QQButton
            onClick={handleReEdit}
            btnText="Re-edit"
            floatRight
            {...btnOverRideDisable}
          />
          <QQButton
            onClick={handleClose}
            btnText="Override"
            floatRight
            {...btnOverRideDisable}
          />
          <QQButton
            onClick={handleApply}
            btnText="Apply"
            floatRight
            {...btnDisableApply}
          />
        </PostalScrubbingButtons>
      </>
    );
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              padding: "5px 15px 20px 20px"
            },
          },
        }}
      >
        {showAddressPopup()}
      </Dialog>
    </div>
  );
}

const PostalScrubbingButtons = styled.div`
  display: grid;
  grid-template-columns: 110px auto auto auto auto;
  margin: 20px 0 0 22px;
  width: 91.5%;
  border-top: 1px solid grey;
  padding: 10px 0 0 0;
`;

const CloseButton = styled.button`
  cursor: pointer;
  float: right;
  marginTop: 5px; 
  border: none;
  color: grey;
  background: none;
`;

const AddressHeader = styled.div`
`;

const ContentDiv = styled.div`
  padding: 0px 24px;
`;

const AddressSection = styled.div`
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
`;
