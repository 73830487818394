import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import QQButton from "./Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function AlertDialog(props) {
  const handleClose = () => {
    props.hideDialog();
    props.setIsSuccess(false);
    props.setSuccessMessage("");
  };

  const handlePrevQuote = () => {
    props.hideDialog();
    props.setPrevQuoteRefresh(false);
    props.clearCart();
  };

  const showSuccessDialog = () => {
    return (
      <>
        <DialogTitle id="alert-dialog-title">
        <FontAwesomeIcon icon="fa-solid fa-circle-check" /> &nbsp;SUCCESS
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.successMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <QQButton
            onClick={handleClose}
            btnText="Ok"
            floatRight
          />
        </DialogActions>
      </>
    );
  };

  const showErrorDialog = () => {
    return (
      <>
        <DialogTitle id="alert-dialog-title">
          <FontAwesomeIcon icon="fa-solid fa-circle-xmark" /> &nbsp;ERROR
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <QQButton
            onClick={props.isPrevQuoteError ? handlePrevQuote : handleClose}
            btnText="Ok"
            floatRight
          />
        </DialogActions>
      </>
    );
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        { props.isSuccess ? showSuccessDialog() : showErrorDialog()}
      </Dialog>
    </div>
  );
}
