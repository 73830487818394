import { unlimitedExistingType, unlimitedIntroType } from "../components/constants";

export const ERRORS = {
  ERR_NON_ZERO_LINES_CHECK: {
    RULE_ID: "ERR_NON_ZERO_LINES_CHECK",
    VALIDATOR: (lines) => lines === 0,
    ERR_MSG:
      "Please choose a minimum of one line to proceed with the quoting process.",
  },
  UNAUTHORIZED_MESSAGE: {
    ERR_MSG:
      "It appears that you have an expired session. Please return to the config page and try again.",
  },
  PREV_QUOTE_VALID_CHECK: {
    RULE_ID: "ERR_PREV_QUOTE_VALID_CHECK",
    VALIDATOR: (pcatResp) => pcatResp.isValid,
    ERR_MSG:
      "Your session has expired. Please enter a new quote for updated pricing information.",
  },
  ERR_NO_SELECTION_CHECK: {
    RULE_ID: "ERR_NO_SELECTION_CHECK",
    VALIDATOR: (totalSelection, gigDataUsage, deviceSelected,unlimitedType) => {
      return (
        totalSelection === 0 &&
        gigDataUsage === -1 &&
        deviceSelected.length === 0 &&
        unlimitedType === -1
      );
    },
    ERR_MSG:
      "There are no selections made. Please update the cart by selecting the number of lines, data plan, and devices.",
  },
  ERR_CONTACT_INFORMATION_NAME_CHECK: {
    RULE_ID: "ERR_CONTACT_INFORMATION_NAME_CHECK",
    VALIDATOR: (contactName) => contactName.trim() === "",
    ERR_MSG: "Name is Required.",
  },
  ERR_CONTACT_INFORMATION_BUSINESS_NAME_CHECK: {
    RULE_ID: "ERR_CONTACT_INFORMATION_BUSINESS_NAME_CHECK",
    VALIDATOR: (businessName) => businessName.trim() === "",
    ERR_MSG: "Business Name is Required.",
  },
  ERR_CONTACT_INFORMATION_ADDRESS_1_CHECK: {
    RULE_ID: "ERR_CONTACT_INFORMATION_ADDRESS_1_CHECK",
    VALIDATOR: (address1) => address1.trim() === "",
    ERR_MSG: "Address Line 1 is Required.",
  },
  ERR_CONTACT_INFORMATION_CITY_CHECK: {
    RULE_ID: "ERR_CONTACT_INFORMATION_CITY_CHECK",
    VALIDATOR: (city) => city.trim() === "",
    ERR_MSG: "City is Required.",
  },
  ERR_CONTACT_INFORMATION_STATE_CHECK: {
    RULE_ID: "ERR_CONTACT_INFORMATION_STATE_CHECK",
    VALIDATOR: (state) => state.trim() === "",
    ERR_MSG: "State is Required.",
  },
  ERR_CONTACT_INFORMATION_ZIP_CHECK: {
    RULE_ID: "ERR_CONTACT_INFORMATION_ZIP_CHECK",
    VALIDATOR: (zip) => zip.trim() === "",
    ERR_MSG: "ZIP is Required.",
  },
  ERR_CONTACT_INFORMATION_ZIP_LENGTH_CHECK: {
    RULE_ID: "ERR_CONTACT_INFORMATION_ZIP_LENGTH_CHECK",
    VALIDATOR: (zip) => (zip.trim() !== "" && zip.length < 5),
    ERR_MSG: "Invalid ZIP Code/Postal Code. Please enter in a valid 5 digit ZIP Code.",
  },
  ERR_CONTACT_INFORMATION_EMAIL_CHECK: {
    RULE_ID: "ERR_CONTACT_INFORMATION_EMAIL_CHECK",
    VALIDATOR: (email) => {
      var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/; //eslint-disable-line
      if (!!email && reg.test(email) === false) {
        return true; // Not valid email, returning true to fail the rule
      }
      return false; // valid email, returning false to pass the rule
    },
    ERR_MSG: "Email Address is not valid.",
  },
  ERR_CONTACT_INFORMATION_EMAIL_REQUIRED_CHECK: {
    RULE_ID: "ERR_CONTACT_INFORMATION_EMAIL_REQUIRED_CHECK",
    VALIDATOR: (email) => email.trim() === "",
    ERR_MSG: "Email Address is Required.",
  },
  ERR_CONTACT_INFORMATION_PHONE_NO_CHECK: {
    RULE_ID: "ERR_CONTACT_INFORMATION_PHONE_NO_CHECK",
    VALIDATOR: (phoneNo) => phoneNo.length !== 10,
    ERR_MSG: "Invalid phone number. Please enter in a valid 10 digit phone number.",
  },
  ERR_CONTACT_INFORMATION_PHONE_NO_NULL_CHECK: {
    RULE_ID: "ERR_CONTACT_INFORMATION_PHONE_NO_NULL_CHECK",
    VALIDATOR: (phoneNo) => phoneNo.length === 0,
    ERR_MSG: "Phone Number is required.",
  },
  ERR_CONTACT_INFORMATION_STATE_LENGTH_CHECK: {
    RULE_ID: "ERR_CONTACT_INFORMATION_STATE_LENGTH_CHECK",
    VALIDATOR: (state) => state.length !== 2,
    ERR_MSG: "Invalid state. Please enter in a valid 2 digit state.",
  },
  ERR_PORT_TN_LIMIT_CHECK: {
    RULE_ID: "ERR_PORT_TN_LIMIT_CHECK",
    VALIDATOR: (portTn, totalLines) => portTn > totalLines,
    ERR_MSG: "The quantity selected for Porting Phone Number(s) must be equal to or less than the total number of Lines (Unlimited or Unlimited Intro + By the Gig). Please modify and validate."
  },
  ERR_IS_ALL_TN_VALID_CHECK: {
    RULE_ID: "ERR_IS_ALL_TN_VALID_CHECK",
    VALIDATOR: (tns) => tns.length > 0 && tns.filter(x => !x.tn || x.tn?.value?.length < 10).length > 0,
    ERR_MSG: "Please enter a valid telephone number (i.e. 10 digits, no special characters)."
  },  
  ERR_TN_IMEI_VALIDATION_COMPLETION_CHECK: {
    RULE_ID: "ERR_TN_IMEI_VALIDATION_COMPLETION_CHECK",
    VALIDATOR: (tns, imei) => (tns.length > 0 && tns.filter(x => !x.isValid).length > 0) || (imei.length > 0 && imei.filter(x => !x.isValid).length > 0),
    ERR_MSG: "Please validate the entered values for Porting and/or BYO Devices. Then proceed to SAVE or UPDATE CART."
  },
  ERR_TN_CMP_MANDATORY_CHECK: {
    RULE_ID: "ERR_TN_CMP_MANDATORY_CHECK",
    VALIDATOR: (tns) => tns.filter(x => x.cmp === -1).length > 0,
    ERR_MSG: "Please select a mobile provider corresponding to the porting phone number."
  },
  ERR_TN_VALIDATION_FAILED: {
    RULE_ID: "ERR_TN_VALIDATION_FAILED",
    ERR_MSG: "The entered phone number(s) is not valid for porting. Please retry."
  },
  ERR_IS_ALL_IMEI_VALID_CHECK: {
    RULE_ID: "ERR_IS_ALL_IMEI_VALID_CHECK",
    VALIDATOR: (imei) => imei.length > 0 && imei.filter(x => x.imei.length < 15).length > 0,
    ERR_MSG: "Please enter a valid IMEI Number (i.e. 15 digits, no special characters)."
  },
  ERR_IMEI_VALIDATION_FAILED: {
    RULE_ID: "ERR_IMEI_VALIDATION_FAILED",
    ERR_MSG: "The entered IMEI(s) is not valid. Please retry."
  },
  ERR_EMAIL_EXHAUSTED: {
    RULE_ID: "ERR_EMAIL_EXHAUSTED",
    ERR_MSG: " Sent to Customer has exceeded the limit. Please modify the email address and try again."
  },
  ERR_EMAIL_EXCEPTION: {
    RULE_ID: "ERR_EMAIL_UNABLE_TO_SEND",
    ERR_MSG: "Unable to generate and send email. Please try again."
  },
  ERR_NO_UNLIMITEDINTROLINES_SELECTION_CHECK: {
    RULE_ID: "ERR_NO_UNLIMITEDINTROLINES_SELECTION_CHECK",
    VALIDATOR: (unlimitedType, unlimitedIntroLines) => {
      return (
        unlimitedType === unlimitedIntroType &&
        unlimitedIntroLines === 0 
      );
    },
    ERR_MSG: "Please identify the number of Unlimited Intro lines, then click UPDATE CART."
  },
  ERR_NO_UNLIMITEDLINES_SELECTION_CHECK: {
    RULE_ID: "ERR_NO_UNLIMITEDLINES_SELECTION_CHECK",
    VALIDATOR: (unlimitedType, unlimitedLines) => {
      return (
        unlimitedType === unlimitedExistingType &&
        unlimitedLines === 0 
      );
    },
    ERR_MSG: "Please identify the number of Unlimited lines, then click UPDATE CART."
  },
  ERR_UNLIMITEDINTRO_MATCH_PLUS_PREMIUM_CHECK: {
    RULE_ID: "ERR_UNLIMITEDINTRO_MATCH_PLUS_PREMIUM_CHECK",
    VALIDATOR: (unlimitedIntroLines,unlimitedPlusLines,unlimitedPremiumLines) => (unlimitedPlusLines+ unlimitedPremiumLines) > unlimitedIntroLines,
    ERR_MSG: "Total lines for Unlimited Plus and Unlimited Premium cannot exceed the number of Unlimited Intro lines. Please revise the number of lines, then click UPDATE CART."
  }
};
