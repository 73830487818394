import React from "react";
import styled from "styled-components";

const Input = ({
  type,
  maxLength,
  inputValue,
  inputOnChange,
  inputName,
  inputDisable
}) => {
  var inputProps = {
    type: type,
    onChange: inputOnChange,
    name: inputName,
    disabled: inputDisable 
  };

  return (
    <QQInput maxLength={maxLength} {...inputProps} value={inputValue || ""} />
  );
};

const QQInput = styled.input`
  padding: 5px;
  width: 85%;
  border: 2px solid #000000;
`;

export default Input;

