import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
/* OVERRIDES */
body#app {
  color: rgba(0, 0, 0, 0.75);
  font-family: Lato, sans-serif;
  background-color: #f4f4f4;
  font-size:14px;
  margin:0;
}


select, input, button {
   font-family: Lato, sans-serif;
   border-radius:0px;
 }

 select {
   background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
   background-position: calc(100% - 0.75rem) center !important;
   -moz-appearance:none !important;
   -webkit-appearance: none !important; 
   appearance: none !important;
   padding: 5px;
}

textarea:focus, input:focus{
 outline: none;
 border-radius:0px;
 outline-width:0;
}

.rc-tooltip .rc-tooltip-inner {
  min-height:0 !important;
  white-space: pre-wrap;
}

h1 {
  margin-top: 0.2em;
  margin-bottom: 0.5em;
  color: rgba(0,0,0,.85);
  font-weight: 500;
}

.phoneNumberField {
  padding: 5px;
  width: 85%;
  border: 2px solid #000000;
}

.inputError
{
  border:2px solid #ff0000
}
`;
