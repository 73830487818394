import React from "react";
import styled from "styled-components";

const Label = ({ labelName, isRequired}) => {
  return (
    <label>
      {labelName}{isRequired && (<RequiredSpan> *</RequiredSpan>)}
    </label>
  );
};

export default Label;

const RequiredSpan = styled.span`
    color: red;
    font-size:16px;
`;