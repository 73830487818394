export const formatPhoneNumber = (tn) => {
    if (tn.length < 4) {
        return `${tn}`;
    }
    if (tn.length < 7) {
        return `(${tn.slice(0,3)}) ${tn.slice(3)}`;
    }
    if (tn.length < 11) {
        return `(${tn.slice(0,3)}) ${tn.slice(3, 6)}-${tn.slice(6)}`;
    }

    return tn;
};