import React from "react";
import Byod from "./byod";
import PropTypes from "prop-types";
import QuickQuoteTitle from "../utility/title";
import WhiteCard from "../utility/whitecard";
import styled from "styled-components";
import DeviceSectionV2 from "./deviceSection_v2";
import { ValidateImei } from "../../services/validation";
import QQButton from "../utility/Button";
import { ERRORS } from "../../constants/errors";
import { SUCCESS } from "../../constants/success";
import { useNavigate } from "react-router-dom";

const Devices = ({ devicesProps }) => {

  const navigate = useNavigate();
  const onClickToggleSection =()=>{
    devicesProps.setToggleSection(
      {
      ...devicesProps.toggleSection,
      devices:!devicesProps.toggleSection.devices
      }
    )
  }

  const {
    byodLines,
    setByodLines,
    imei,
    setImei,
    linesList
  } = devicesProps;

  const byodProps = {
    byodLines,
    setByodLines,
    imei,
    setImei,
    linesList
  };

  const validateImei = () => {
    devicesProps.setShowLoader(true);
    devicesProps.setValidationSpinner(true);

    if(ERRORS.ERR_IS_ALL_IMEI_VALID_CHECK.VALIDATOR(imei)) {
      devicesProps.showDialog();
      devicesProps.setErrorMessage(ERRORS.ERR_IS_ALL_IMEI_VALID_CHECK.ERR_MSG);
      devicesProps.setShowLoader(false);
      devicesProps.setValidationSpinner(false);
      return;
    }

    const payload = {
      imeiNumbers: imei.map(({id, imei}) => ({id, imei}))
    }

    setTimeout(() => {
      ValidateImei(payload)
      .then(response => {
        if (response.responseStatus) {
          setImei(response.responseData);

          if (SUCCESS.SUCCESS_ALL_IMEI_VALIDATED.VALIDATOR(response.responseData)) {
            devicesProps.showDialog();
            devicesProps.setIsSuccess(true);
            devicesProps.setSuccessMessage(SUCCESS.SUCCESS_ALL_IMEI_VALIDATED.SUCCESS_MSG);          
          } else {
            devicesProps.showDialog();
            devicesProps.setErrorMessage(ERRORS.ERR_IMEI_VALIDATION_FAILED.ERR_MSG);
          }
        }
        devicesProps.setShowLoader(false);
        devicesProps.setValidationSpinner(false);
      })
      .catch(err => {
        //should show some server error occured while trying to talk with the server
        //alert('server error occured');
        if (err?.response?.status === 401) {
          navigate("/unauthorize");
        }
        devicesProps.showDialog();
        devicesProps.setErrorMessage('Data was unable to validate. Please try again.');        
        devicesProps.setShowLoader(false);
        devicesProps.setValidationSpinner(false);
      });
    }, 2000);
  };

  return (
    <DevicesStyles>
      <QuickQuoteTitle titleName="DEVICES" isMarginBottom onClick={() => onClickToggleSection()} showSection={devicesProps.toggleSection.devices} showToggleBtn={true} />
      <DeviceContainer showSection={devicesProps.toggleSection.devices}>
        <WhiteCard>
          <DeviceWrapper>            
            <DeviceRow>
              <DevicesLabel>
                Search for New Devices
              </DevicesLabel>
              <SearchDevice>
                {/* <DeviceSection
                name="devices"
                id="devices"
                devices={devicesProps.device}
                setDevice={devicesProps.setDevice}
              /> */}
                <DeviceSectionV2
                  name="devices"
                  id="devices"
                  totalLines={devicesProps.totalLines}
                  linesList={devicesProps.linesList}
                  deviceList={devicesProps.deviceList}
                  devices={devicesProps.device}
                  setDevice={devicesProps.setDevice}
                  paymentDropdownList={devicesProps.paymentDropdownList}
                  setPaymentDropdownList={devicesProps.setPaymentDropdownList}
                />
              </SearchDevice>
            </DeviceRow>            
          </DeviceWrapper>
        </WhiteCard>
        <WhiteCard>
          <Byod byodProps={byodProps}/>
        </WhiteCard>
        
        {byodLines > 0 ? (
          <QQButton onClick={validateImei} btnText="Validate" floatRight />
        ) : null}
      </DeviceContainer>
    </DevicesStyles>
  );
};

//#region Devices proptypes
Devices.propTypes = {
  byodLines: PropTypes.number,
  setByodLines: PropTypes.func,
  deviceLines: PropTypes.number,
  setDeviceLines: PropTypes.func,
  device: PropTypes.array,
  setDevice: PropTypes.func,
  paymentMethod: PropTypes.string,
  setPaymentMethod: PropTypes.func,
  paymentDropdownList: PropTypes.array,
  setPaymentDropdownList: PropTypes.func,
  deviceList: PropTypes.array,
  toolTips:PropTypes.array,
  linesList: PropTypes.array,
  totalLines: PropTypes.number,
  imei: PropTypes.array,
  setImei: PropTypes.func,
  setIsSuccess: PropTypes.func,
  setSuccessMessage: PropTypes.func,
  showDialog: PropTypes.func,
  setErrorMessage: PropTypes.func
};
//#endregion

//#region styled-components
const DeviceContainer = styled.div`
  max-height: 0;
  ${({showSection}) => showSection ? 'max-height: 1300px;' : 'overflow: hidden;' }
  transition: max-height 0.15s linear; 
`;

const DevicesStyles = styled.div`
  padding: 10px;
`;

const DeviceRow = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 50px;
`;

const SearchDevice = styled.div`
  display: grid;
  grid-template-columns: auto;
`;

const DeviceWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
`;

const DevicesLabel = styled.h4`
  margin: 10px;
  font-weight: 400;
  font-size: 16px;
`;
//#endregion

export default Devices;