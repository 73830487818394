import axios from "axios";
import { useCallback, useEffect, useRef } from "react";

const AuthorizationRequest = ({ bearerToken }) => {
  const interceptorsRef = useRef();

  const requestMiddleware = useCallback(async (config) => {
    if (config.disableAuthorization) {
      return config;
    }

    return {
      ...config,
      headers: {
        ...config.headers,
        ...(!!bearerToken && {
          Authorization: `Bearer ${bearerToken}`,
        }),
      },
    };
  },[bearerToken]);

  useEffect(() => {
    const { current: currentInterceptors } = interceptorsRef;
    if (currentInterceptors) {
      axios.interceptors.request.eject(currentInterceptors.request);
    }

    interceptorsRef.current = {
      request: axios.interceptors.request.use(requestMiddleware),
    };
  }, [requestMiddleware]);
  return "";
};

export default AuthorizationRequest;
