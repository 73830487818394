const { ENV_MAP = {}, ...ENV } = process.env
const HOST_ENV = window.env?.HOST_ENV
const API_ENV = window.env?.API_ENV || HOST_ENV
const HOST_ENV_REGEX = /<API_ENV>/

const COMBINED_ENVS = Object.entries({
  ...ENV,
  API_ENV,
  HOST_ENV,
  ...ENV_MAP[HOST_ENV]
}).reduce(
  (vars, [key, value]) => ({
    ...vars,
    [key]: value?.replace(HOST_ENV_REGEX, API_ENV)
  }),
  {}
)

export function getEnv() {
  return COMBINED_ENVS
}
