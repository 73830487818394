import React from "react";
import './config/axios.interceptor';
import Quote from "./components/landing/quote";
import { GlobalStyle } from "./theme";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { faFontAwesome } from '@fortawesome/free-regular-svg-icons';
import { Routes, Route } from "react-router-dom";
import UnAuthorize from './components/utility/unauthorize';
import AuthorizeContainer from './components/container/authorizeContainer';
import { useState } from 'react';
import { QuickQuoteSession } from './entities/quickquotesession';

function App() {
  library.add(fas, faFontAwesome);
  const [quickQuoteSessionInfo, setQuickQuoteSessionInfo] = useState(new QuickQuoteSession()); 
  return (
    <div>
      <GlobalStyle />
      <AuthorizeContainer setQuickQuoteSessionState={setQuickQuoteSessionInfo} />
      {
        <Routes>
          <Route path="/quickquote" element={<Quote qqSession={quickQuoteSessionInfo} />} />
          <Route path="/unAuthorize" element={ <UnAuthorize />  } />
        </Routes>
      }
    </div>
  );
}

export default App;
