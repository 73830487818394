import React from "react";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { GetQuickQuoteSession } from "../../services/authorize";
import { QuickQuoteSession } from "../../entities/quickquotesession";
import AuthorizationRequest from "./authorizationrequest";
import { GetEnv, LocallyAuthenticate } from "../../local/local";

const AuthorizeContainer = ({ setQuickQuoteSessionState }) => {
  let correlationIdParams = window.location.pathname.substring(1);
  let navigate = useNavigate();
  const qqSessionDetails = useRef();

  useEffect(() => {
    (async () => {
      if(GetEnv() === "local") { 
        try {
          const response = await LocallyAuthenticate();
          if(!!response) {
            correlationIdParams = response; // eslint-disable-line react-hooks/exhaustive-deps
          }          
        } catch (error) {
          navigate("/unauthorize");
          return;
        }       
      }
      
      try {
        const qqSessionResponse = await GetQuickQuoteSession(correlationIdParams);
        if (qqSessionResponse != null) {
          qqSessionDetails.current = new QuickQuoteSession(
            qqSessionResponse.quickQuoteContext.opportunityId,
            qqSessionResponse.quickQuoteContext.sessionId,
            qqSessionResponse.quickQuoteContext.fxbuyflowsessionId,
            qqSessionResponse.token.accessToken,
            qqSessionResponse.quickQuoteContext.agentId
          );
  
          setQuickQuoteSessionState(qqSessionDetails.current);
         
          navigate("/quickquote");
        } else {
          navigate("/unauthorize");
          return;
        }        
      } catch (error) {
        navigate("/unauthorize");
        return;
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (qqSessionDetails && <AuthorizationRequest bearerToken={qqSessionDetails?.current?.accessToken}/>);
};

export default AuthorizeContainer;
