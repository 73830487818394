export const SUCCESS = {
    SUCCESS_ALL_TN_VALIDATED: {
        RULE_ID: "SUCCESS_ALL_TN_VALIDATED",
        VALIDATOR: (tns) => tns.length > 0 && tns.filter(x => !x.isValid).length === 0,
        SUCCESS_MSG: "Success! The phone number(s) can be ported.",
    },    
    SUCCESS_UPDATED_CART: {
        RULE_ID: "SUCCESS_UPDATED_CART",
        VALIDATOR: (response) => response.responseStatus,
        SUCCESS_MSG: "Cart Summary has been updated."
    },
    SUCCESS_ALL_IMEI_VALIDATED: {
        RULE_ID: "SUCCESS_ALL_IMEI_VALIDATED",
        VALIDATOR: (imei) => imei.length > 0 && imei.filter(x => !x.isValid).length === 0,
        SUCCESS_MSG: "Success! The device(s) is eligible.",
    },
    SUCCESS_EMAIL_SENT_TO_CUSTOMER: {
        RULE_ID: "SUCCESS_EMAIL_TO_CUSTOMER",
        VALIDATOR: (response) => response.responseStatus,
        SUCCESS_MSG: "An email has been sent to the customer successfully!"
    }
};