
import axios from "axios";
import { POST_SAVE_QUOTE, DELETE_QUOTE, CALCULATE_QUOTE } from "../constants/urls";

//get cart
//save cart
export const SaveConfigCart = async (payload) => {
    try {
        const response = await axios.post(POST_SAVE_QUOTE, payload);
        const saveResponse = {
            responseData: response.data?.value?.data,
            responseStatus: (response.status === 200) ? true : false 
        };
        return saveResponse;        
    } catch (error) {
        return Promise.reject(error);
    }
};

//clear cart
export const ClearCart = async (opportunityId) => {
    try {
        const response = await axios.delete(DELETE_QUOTE + `/${opportunityId}`);
        return response.status === 200;        
    } catch (error) {
        return Promise.reject(error);
    }
}

//calculateCart
export const CalculateCart = async (payload) => {
    try {
        const response = await axios.post(CALCULATE_QUOTE, payload);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

//validate cart