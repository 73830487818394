import React from "react";
import styled from "styled-components";
import QQButton from "../../utility/Button";
import Headertitle from "./headertitle";
import CustomerInfo from "../../customer/customerinfo";
import { qqHomeButton } from "../../../services/navigation";

const Header = ({
  updateCart,
  customerInformation,
  uiSession,
  lastQuoteSavedDate,
  setShowLoader
}) => {

const concatOppName =
    customerInformation?.opportunityNumber +
    " - " +
    customerInformation?.opportunityName;
  return (
    <div>
      <QQGridContainer>
        <QQHeaderSection>
          <Headertitle title="SESSION ID" value={uiSession.sessionId} />
          <QQHeaderButton>
            <QQDefaultButton>Quick Quote</QQDefaultButton>
          </QQHeaderButton>
          <QQButton
            onClick={() => qqHomeButton(setShowLoader, uiSession.opportunityId)}
            btnText="Home"
          />
          <QQButton onClick={updateCart} btnText="Save" />
          <OppHeaderGrid>
            <Headertitle
              title="OPPORTUNITY"
              value={concatOppName}
              showOppLink={true}
              opportunityId={uiSession.opportunityId}
              setShowLoader={setShowLoader}
            />
          </OppHeaderGrid>
          <LastQuoteSavedGrid>
            {lastQuoteSavedDate && (
              <Headertitle
                title="LAST QUOTE SAVED"
                value={lastQuoteSavedDate}
              />
            )}
          </LastQuoteSavedGrid>
        </QQHeaderSection>
        <div></div>
        <div>
          <CustomerInfo customerInformation={customerInformation} />
        </div>
      </QQGridContainer>
    </div>
  );
};

const QQGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1.25fr .07fr 1fr;
  padding: 10px;
`;

const OppHeaderGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`;

const LastQuoteSavedGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 5px;
`;

const QQHeaderSection = styled.div`
  padding: 10px;
`;

const QQHeaderButton = styled.nav`
  margin-top: 15px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 300px;
`;

const QQDefaultButton = styled.button`
  border: none;
  background: rgb(229, 229, 229);
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  height: 55px;
  font-size: 18px;
  text-transform: none;
  font-weight: 400;
  flex: 1 1 0%;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  outline: none;
  border-radius: 40px;
  min-width: 140px;
  padding: 0px 30px;
`;
export default Header;
