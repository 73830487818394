import React from "react";
import RCTooltip from "rc-tooltip";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const QQTooltip = (props) => {
  return (
    <>
      <RCTooltip
        placement="right"
        trigger={["hover"]}
        overlay={<span>{props.toolTipText}</span>}
      >
        <TooltopButton>
          <FontAwesomeIcon icon="fa-solid fa-circle-question" />
        </TooltopButton>
      </RCTooltip>
    </>
  );
};

export default QQTooltip;

const TooltopButton = styled.span`
  color: rgb(24, 144, 255);
  font-size: 14px;
  height: 1em;
  margin-left: 5px;
`;
