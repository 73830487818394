import React from "react";
import DropDown from "../utility/dropdown";
import PropTypes from "prop-types";
import QuickQuoteTitle from "../utility/title";
import styled from "styled-components";
import WhiteCard from "../utility/whitecard";
import QQTooltip from "../utility/RCTooltip";
import PortTn from "./portTn";
import QQButton from "../utility/Button";
import { ERRORS } from "../../constants/errors";
import { SUCCESS } from "../../constants/success";
import { tollFreeCodeList, unlimitedExistingType, unlimitedIntroType } from "../constants";

const Lines = ({ linesProps }) => {
  const onClickToggleSection =()=>{
    linesProps.setToggleSection(
      {
      ...linesProps.toggleSection,
      lines:!linesProps.toggleSection.lines
      }
    )
  }

  const validateTn = () => {
    if (linesProps.tns.length === 0) {
      alert(
        "Please enter a minimum of one phone number to initiate validation"
      );
      return;
    }

    linesProps.setShowLoader(true);
    linesProps.setValidationSpinner(true);

    //check if portTn is less than or equal to (Unlimited + Gig Lines)
    const totalLines = linesProps.unlimitedType === unlimitedIntroType ? (linesProps.unlimitedIntroLines + linesProps.gigLines):(linesProps.unlimitedLines + linesProps.gigLines);
    const isPortTnInValid = ERRORS.ERR_PORT_TN_LIMIT_CHECK.VALIDATOR(linesProps.portTn, totalLines);

    //check if all the tns are entered and is 10 digits
    //If all the entered phone numbers are valid
    const isAnyTnInValid = ERRORS.ERR_IS_ALL_TN_VALID_CHECK.VALIDATOR(linesProps.tns);
                        
    //check if all the cmps are provided
    const isAnyCmpNotEntered = ERRORS.ERR_TN_CMP_MANDATORY_CHECK.VALIDATOR(linesProps.tns);

    if (isPortTnInValid || isAnyTnInValid || isAnyCmpNotEntered) {
      linesProps.setShowLoader(false);
      linesProps.setValidationSpinner(false);    
      
      //Prioriy 1: Number of Tns to be ported should be less than or equal to (Unlimited + By the Gig).
      if (isPortTnInValid) {
        //show invalid error message
        linesProps.showDialog();
        linesProps.setErrorMessage(ERRORS.ERR_PORT_TN_LIMIT_CHECK.ERR_MSG);
        return;
      }
  
      //Priority 2: Tn validity
      if (isAnyTnInValid) {
        //show invalid error message
        linesProps.showDialog();
        linesProps.setErrorMessage(ERRORS.ERR_IS_ALL_TN_VALID_CHECK.ERR_MSG);
        return;
      }
  
      //Priority 2: CMP validity
      if (isAnyCmpNotEntered) {
        //show invalid error message
        linesProps.showDialog();
        //Please select a provider for the following ported number (XXX) XXX-XXXX
        linesProps.setErrorMessage(ERRORS.ERR_TN_CMP_MANDATORY_CHECK.ERR_MSG);
        return;
      }
    }

    const validatedTnList = linesProps.tns.map((teleNum) => {
      const newteleNum = { ...teleNum };

      if (tollFreeCodeList.findIndex(x => x === newteleNum.tn?.value?.slice(0,3)) === -1) {
        newteleNum.isValid = true;
      } else {
        newteleNum.isValid = false;
      }

      return newteleNum;
    });

    //For testing purpose - We have delayed the validation for 2 seconds to mimic that it is done in backend
    setTimeout(() => {      
      linesProps.setShowLoader(false);
      linesProps.setValidationSpinner(false);     

      linesProps.setTns(validatedTnList);  

      if (SUCCESS.SUCCESS_ALL_TN_VALIDATED.VALIDATOR(validatedTnList)) {
        //then show success              
        linesProps.showDialog();
        linesProps.setIsSuccess(true);
        linesProps.setSuccessMessage(SUCCESS.SUCCESS_ALL_TN_VALIDATED.SUCCESS_MSG);
      } else {
        //REACHABLE CODE
        //This error message will never be reached as per the current scenario --> This needs to be arrested
        //
        //show invalid error message
        linesProps.showDialog();
        linesProps.setErrorMessage(ERRORS.ERR_TN_VALIDATION_FAILED.ERR_MSG);
      }
    }, 2000);
  };

  const handleUnlimitedChange = (e) => {
    linesProps.setUnlimitedType(+e.target.value);
      linesProps.setUnlimitedLines(0);
      linesProps.setUnlimitedIntroLines(0);
      linesProps.setUnlimitedPlusLines(0);
      linesProps.setUnlimitedPremiumLines(0);
  }

  const handleUnlimitedIntroLinesChange =(unlimitedIntroLines) =>{
    linesProps.setUnlimitedIntroLines(unlimitedIntroLines);
    if(unlimitedIntroLines === 0)
    {
      linesProps.setUnlimitedPlusLines(0);
      linesProps.setUnlimitedPremiumLines(0);
    }
  }

  return (
    <LinesSection>
      <QuickQuoteTitle
        titleName="LINES AND DATA"
        onClick={() => onClickToggleSection()}
        showSection={linesProps.toggleSection.lines}
        showToggleBtn={true}
      />
      <Container showSection={linesProps.toggleSection.lines}>
        <UnlimitedLabel style={{ marginLeft: "0px" , fontSize:"16px" }}>Lines</UnlimitedLabel>
        <WhiteCard>
        <LinesRow>
          <BytheGigDropdown
            marginLeft={"10px"}
            name="gig"
            id="gig"
            value={linesProps.gigDataUsage}
            onChange={(e) => linesProps.setGigDataUsage(+e.target.value)}
          >
            {linesProps.gigDataUsageList.map((gig) => (
              <option key={gig.id} value={gig.id}>
                {gig.name}
              </option>
            ))}
          </BytheGigDropdown>
          <span>
            <QQTooltip toolTipText={linesProps?.toolTips?.ByTheGig?.text} />
          </span>
          <DropDown
            list={linesProps.linesList}
            id="gig_lines"
            name="gig"
            selected={linesProps.gigLines}
            handleChange={linesProps.setGigLines}
          />
        </LinesRow>
        </WhiteCard>
        <WhiteCard>
        <UnlimitedTypeGrid>
        <UnlimitedLabel>
              Which Unlimited Plan?
              <QQTooltip toolTipText={linesProps?.toolTips?.Unlimited?.text} />
        </UnlimitedLabel>
        <span></span>
        <span></span>
        <BytheGigDropdown
            marginLeft={"70px"}
            marginright={"20px"}
            name="unlimited_type"
            id="unlimitedType"
            value={linesProps.unlimitedType}
            onChange={(e) => handleUnlimitedChange(e)}
          > 
            {linesProps.unlimitedTypeList.map((type) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </BytheGigDropdown>
      </UnlimitedTypeGrid>
      </WhiteCard>
       {linesProps.unlimitedType === unlimitedExistingType ?
       <WhiteCard>
       <UnlimitedGrid>
       <UnlimitedLabel>
         How many Unlimited lines? (for existing customers only){" "}
       </UnlimitedLabel>
       <span></span>
       <span></span>
       <DropDown
         list={linesProps.linesList}
         id="unlimited_lines"
         name="unlimited"
         selected={linesProps.unlimitedLines}
         handleChange={linesProps.setUnlimitedLines}
       />
     </UnlimitedGrid></WhiteCard> : linesProps.unlimitedType === unlimitedIntroType ?
     (<WhiteCard>
     <UnlimitedGrid>
            <UnlimitedLabel>
              How many Unlimited Intro lines?
            </UnlimitedLabel>
            <span></span>
            <span></span>
            <DropDown
              list={linesProps.linesList}
              id="unlimitedIntro_lines"
              name="unlimitedIntro"
              selected={linesProps.unlimitedIntroLines}
              handleChange={(e) => handleUnlimitedIntroLinesChange(e)}
            />
          </UnlimitedGrid>
          {linesProps.unlimitedIntroLines > 0 ?
         ( <WhiteCard>
         <UnlimitedPlusGrid>
            <UnlimitedIntroLabel>
            Upgrade to Unlimited Plus? -
            </UnlimitedIntroLabel>
            <UnlimitedIntroTextLabel>
             Additional $10/line
            </UnlimitedIntroTextLabel>
            <span></span>
            <span></span>
            <DropDown
              list={linesProps.linesList}
              id="unlimitedPlus_lines"
              name="unlimitedPlus"
              selected={linesProps.unlimitedPlusLines}
              handleChange={linesProps.setUnlimitedPlusLines}
            />
          </UnlimitedPlusGrid>
          <UnlimitedPremiumGrid>
            <UnlimitedIntroLabel>
            Upgrade to Unlimited Premium? - 
            </UnlimitedIntroLabel>
            <UnlimitedIntroTextLabel>
             Additional $20/line
            </UnlimitedIntroTextLabel>
            <span></span>
            <span></span>
            <DropDown
              list={linesProps.linesList}
              id="unlimitedPremium_lines"
              name="unlimitedPremium"
              selected={linesProps.unlimitedPremiumLines}
              handleChange={linesProps.setUnlimitedPremiumLines}
            />
          </UnlimitedPremiumGrid> </WhiteCard>) : null
}
          </WhiteCard> ) : null
     }
        <WhiteCard>
          <PortTn
            linesList={linesProps.linesList}
            tns={linesProps.tns}
            setTns={linesProps.setTns}
            portTn={linesProps.portTn}
            setPortTn={linesProps.setPortTn}
          />
        </WhiteCard>

        {linesProps.portTn > 0 ? (
          <QQButton onClick={validateTn} btnText="Validate" floatRight />
        ) : null}
      </Container>
    </LinesSection>
  );
};

//#region Lines proptypes
Lines.propTypes = {
  unlimitedLines: PropTypes.number,
  setUnlimitedLines: PropTypes.func,
  unlimitedIntroLines: PropTypes.number,
  setUnlimitedIntroLines: PropTypes.func,
  unlimitedPlusLines: PropTypes.number,
  setUnlimitedPlusLines: PropTypes.func,
  unlimitedPremiumLines: PropTypes.number,
  setUnlimitedPremiumLines: PropTypes.func,
  unlimitedType: PropTypes.number,
  setUnlimitedType: PropTypes.func,
  gigLines: PropTypes.number,
  setGigLines: PropTypes.func,
  gigDataUsage: PropTypes.number,
  setGigDataUsage: PropTypes.func,
  unlimitedTypeList: PropTypes.array,
  gigDataUsageList: PropTypes.array,
  toolTips: PropTypes.array,
  tns: PropTypes.array,
  setTns: PropTypes.func,
  portTn: PropTypes.number,
  setPortTn: PropTypes.func,
  setIsValid: PropTypes.func,
  setOpen: PropTypes.func,
  setErrorMessage: PropTypes.func,  
  setIsSuccess: PropTypes.func,
  setSuccessMessage: PropTypes.func,
  showDialog: PropTypes.func,
  linesList: PropTypes.array
};
//#endregion

//#region styled-components
const BytheGigDropdown = styled.select`
  height: 32px;
  min-width: 130px;
  font-size: 14px;
  line-height: 1rem;
  background-position: right 8px center;
  background-size: auto;
  font-weight: 500;
  color: #000000;
  border: 2px solid #000000;
  background-color: #ffffff;
  border-radius: 0;
  background-repeat: no-repeat;
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginright};
`;

const UnlimitedLabel = styled.h4`
  margin: 10px;
  font-weight: 400;
  font-size: 15px;
`;

const UnlimitedIntroLabel = styled.h4`
  margin-top: 10px;
  margin-left: 10px;
  font-weight: 400;
  font-size: 15px;
`;
const UnlimitedIntroTextLabel = styled.h3`
  margin-top: 10px;
  font-weight: 200;
  font-size: 15px;
`;

const LinesSection = styled.div`
  padding: 10px;
  padding-top: 20px;
`;

const LinesRow = styled.div`
  display: grid;
  grid-template-columns: auto auto 90px;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const UnlimitedGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto 90px;
`;

const UnlimitedPlusGrid = styled.div`
  display: grid;
  grid-template-columns: 200px auto auto auto 90px;
`;

const UnlimitedPremiumGrid = styled.div`
  display: grid;
  grid-template-columns: 231px auto auto auto 90px;
`;

const UnlimitedTypeGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto 220px;
`;
const Container = styled.div`
  max-height: 0;
  ${({ showSection }) =>
    showSection
      ? `
    max-height: 1500px;      
  `
      : ""}
  transition: max-height 0.15s linear;
  overflow: hidden;
`;
//#endregion

export default Lines;
