import React from 'react';
import styled from "styled-components";

const TnTextBox = ({id, value, handleChange}) => {
    const displayTnValue = (tn) => {
        return !!tn ? tn.displayValue : "";
    };

    return (
        <QQInput id={id} placeholder="Enter Phone Number" type="text" value={displayTnValue(value)} onChange={handleChange}/>
    );
};

const QQInput = styled.input`
  padding: 5px;
  width: 85%;
  border: 2px solid #000000;
`;

export default TnTextBox;