import axios from "axios";
import { emailExhaustedStatus, emailNotSentStatus, emailSuccessStatus } from "../components/constants";
import { ERRORS } from "../constants/errors";

import {
    GET_QQ_SEND_EMAIL_TO_CUSTOMER,
    POST_QQ_SEND_EMAIL_TO_CUSTOMER,
  } from "../constants/urls";

export const SendQQToCustomer = async (opportunityNumber,opportunityId, emailAddress, agentId) => {
    try {
      const response = await axios.post(
        POST_QQ_SEND_EMAIL_TO_CUSTOMER,
        {
          opportunityNumber: opportunityNumber,
          opportunityId: opportunityId,
          emailAddress: emailAddress,
          agentId:agentId
        }
      );
  
      const { data } = response;
      if (response?.status === 200) {
        // Check Bad Request
        if (data?.statusCode === 200) {
          // If Email sent Successfully
          return {
            result: emailSuccessStatus,
            data: data?.value?.data,
          };
        } else {
          if (data?.statusCode === 400) {
            if (data?.value?.messages?.length > 0) {
              if (
                data?.value?.messages[0].code ===
                ERRORS.ERR_EMAIL_EXHAUSTED.RULE_ID
              ) {
                return {
                  result: emailExhaustedStatus,
                  errorMessage: ERRORS.ERR_EMAIL_EXHAUSTED.ERR_MSG,
                };
              } else if (data?.value?.messages[0].code === ERRORS.ERR_EMAIL_EXCEPTION.RULE_ID) {
                return {
                  result: emailNotSentStatus,
                  errorMessage: ERRORS.ERR_EMAIL_UNABLE_TO_SEND.ERR_MSG,
                };
              }
            }
          }
        }
      } else {
        return {
          result: emailNotSentStatus,
          errorMessage: ERRORS.ERR_EMAIL_UNABLE_TO_SEND.ERR_MSG
        }
      }
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const GetSendQQToCustomerDetails = async (
    opportunityId,
    emailAddress,
    agentId
  ) => {
    try {
      const {
        data: {
          value: { data: result },
        },
      } = await axios.get(
          GET_QQ_SEND_EMAIL_TO_CUSTOMER +
          `/${opportunityId}` +
          `/${emailAddress}` +
          `/${agentId}`
      );
      //  const emailResult = await axios.get(GET_QQ_SEND_EMAIL_TO_CUSTOMER);
      return result;
    } catch (err) {
      return Promise.reject(err);
    }
  };
  