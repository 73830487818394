import React from "react";
import styled from "styled-components";

const PortImei = ({ imei, setImei }) => {
  const handleImeiChange = (newImei, i) => {
    const updatedImeiList = imei.map((imei, index) => {
      if (index === i) {
        const imeiNum = { ...imei };

        const plainImeiValue = newImei.replace(/[^\d]/g, "");

        if (plainImeiValue.length < 16) {
          imeiNum.imei = plainImeiValue;
        }

        //forcing revalidation when the user modifies the imei number
        imeiNum.isValid = false;

        return imeiNum;
      }
      return imei;
    });
    setImei(updatedImeiList);
  };

  return (
    <ImeiContainer>
      <ImeiHeader>
        <ImeiLabel>IMEI</ImeiLabel> 
        <ImeiLabel style={{ margin: "auto 0px auto 50px"}}>Validated</ImeiLabel>
      </ImeiHeader>
      <ImeiListContainer>
        {imei.map((x, i) => (
          <ImeiListItem key={x.id}>
            <QQInput
              id={`imei-input-${x.id}`}
              type="text"
              value={x.imei}
              onChange={(e) => handleImeiChange(e.target.value, i)}
              placeholder="Enter in IMEI Number"
            />
            <input
              id={`imei-checkbox-${x.id}`}
              type="checkbox"
              checked={x.isValid}
              readOnly
              style={{
                margin: "auto 0px auto 70px",
                width: "20px",
                height: "20px",
              }}
            />
          </ImeiListItem>
        ))}
      </ImeiListContainer>
    </ImeiContainer>
  );
};

const ImeiContainer = styled.div``;

const ImeiHeader = styled.div`
    display: grid;
    grid-template-columns: 1.35fr 1fr;
`;

const QQInput = styled.input`
  padding: 5px;
  border: 2px solid #000000;
`;

const ImeiLabel = styled.h4`
  margin: 10px 10px 10px 0;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 10px 0 0;
`;

const ImeiListContainer = styled.ul`
  padding-left: 0px;
`;

const ImeiListItem = styled.li`
  display: grid;
  grid-template-columns: 1.35fr 1fr;
  margin-bottom: 10px;
`;

export default PortImei;
