import styled from "styled-components";
import React from "react";
import DropDown from "../utility/dropdown";
import MobileDetails from "./mobileDetails";

const defaultTnOptions = {
    id: 0,
    tn: null,
    // {
    //     displayValue: "(hello-hello)",
    //     value: "hellohello"
    // },
    cmp: -1,
    isValid: false
};

const PortTn = ({tns, setTns, portTn, setPortTn, linesList}) => {
    const handlePortTnChange = (num) => {
        createTnInputList(num);
        setPortTn(num);
    };

    const createTnInputList = (num) => {
        let counter = 0;
        let newTnsList = [];

        if (tns.length === 0) {
            while(counter < num) {
                newTnsList.push({...defaultTnOptions, id: counter});
                counter++;    
            }
        }
        else if (num > tns.length) { 
            counter = num - tns.length;  
            newTnsList = [...tns];         
            //to start the counting from 
            let id = tns.length;
            while(counter > 0) {
                newTnsList.push({...defaultTnOptions, id: id++});
                counter--;    
            }
        } else {
            counter = tns.length - num;
            newTnsList = [...tns];
            while(counter > 0) {
                newTnsList.pop();
                counter--;    
            }            
        }
        
        setTns(newTnsList);
    };

    return (
        <PortTnContainer>
            <PortQuestion>Does the customer want to PORT their phone number? If so, how many phone numbers to be PORTED?</PortQuestion>
            <DropDown 
                id="port_tn"
                name="port_tn"
                selected={portTn}
                handleChange={handlePortTnChange}            
                list={linesList}
            />
            {portTn > 0 ? <MobileDetails tns={tns} setTns={setTns}/> : null}            
        </PortTnContainer>
    );
}

const PortTnContainer = styled.div`
    padding: 10px;
    display: grid;
    grid-template-columns: auto 80px;
    align-items: center;
`;

const PortQuestion = styled.span`
   font-size: 15px
`;

export default PortTn;