import React from "react";
import styled, { css } from "styled-components";
import QuickQuoteTitle from "../utility/title";
import { defaultAdditionalOptions, defaultCartItems } from "../constants";

const ListCartItems = (list, isDefault) => {
  return (
    <div>
      {list.map((x) => (
        <CartItems key={x.id} isDefault={isDefault}>
          <div>{x.units}</div>
          <div>{x.name}</div>
          <div>{x.unitPrice?.toFixed(2)}</div>
          <div>{x.mrc?.toFixed(2)}</div>
          <div>{x.nrc?.toFixed(2)}</div>
        </CartItems>
      ))}
    </div>
  );
};

const CartSummary = (props) => {
  return (
    <div>
      {!!props.cart.cartItems ? (
        <div>
          <StyledContainer>
            <StyledTableHeader>
              <div>#</div>
              <div>Item</div>
              <div>UNIT</div>
              <div>MRC</div>
              <div>NRC</div>
            </StyledTableHeader>
            <StyledTableHeading>Comcast Business Mobile</StyledTableHeading>
          </StyledContainer>
          {ListCartItems(props.cart.cartItems, false)}
          <StyledTableHeading>Additional Options</StyledTableHeading>
          {ListCartItems(defaultAdditionalOptions, true)}
          <StyledTableFooter>
            <div>
              <TotalQuoteStyle>
                TOTAL QUOTE<sup>*</sup>
              </TotalQuoteStyle>
              <DisclaimerSpan>
                <sup>*</sup>Does not include taxes, fees, or other charges
              </DisclaimerSpan>
            </div>
            <div>
              <h4>$ {props.cart.totalMrc.toFixed(2)}</h4>
            </div>
            <div>
              <h4>$ {props.cart.totalNrc.toFixed(2)}</h4>
            </div>
          </StyledTableFooter>
          <QuickQuoteTitle />
          <StyledTableSavingsFooter>
            <div>
              <TotalQuoteStyle>
                TOTAL SAVINGS<sup>*</sup>
              </TotalQuoteStyle>
              <DisclaimerSpan>
                <sup>*</sup>Does not include Monthly Device Payments
              </DisclaimerSpan>
            </div>
            <div>
              <h2 style={{ margin: "0px" }}>
                <label style={{ fontSize: "12px" }}>$</label>{" "}
                {props.cart.monthlySavings.toFixed(2)}
              </h2>
              <SavingsSpan>Savings/Month</SavingsSpan>
            </div>
            <div>
              <h2 style={{ margin: "0px" }}>
                <label style={{ fontSize: "12px" }}>$</label>{" "}
                {(props.cart.monthlySavings.toFixed(2) * 12).toFixed(2)}
              </h2>
              <SavingsSpan>Savings/Year</SavingsSpan>
            </div>
          </StyledTableSavingsFooter>
          <QuickQuoteTitle isMarginBottom />
        </div>
      ) : (
        <div>
          <StyledContainer>
            <StyledTableHeader>
              <div>#</div>
              <div>Item</div>
              <div>UNIT</div>
              <div>MRC</div>
              <div>NRC</div>
            </StyledTableHeader>
            <StyledTableHeading>Comcast Business Mobile</StyledTableHeading>
          </StyledContainer>
          {ListCartItems(defaultCartItems, true)}
          <StyledTableHeading>Additional Options</StyledTableHeading>
          {ListCartItems(defaultAdditionalOptions, true)}
          <StyledTableFooter>
            <div>
              <TotalQuoteStyle>
                TOTAL QUOTE<sup>*</sup>
              </TotalQuoteStyle>
              <DisclaimerSpan>
                <sup>*</sup>Does not include taxes, fees, or other charges
              </DisclaimerSpan>
            </div>
            <div>
              <h4>$ 0.00</h4>
            </div>
            <div>
              <h4>$ 0.00</h4>
            </div>
          </StyledTableFooter>
          <QuickQuoteTitle />
          <StyledTableSavingsFooter>
            <div>
            <TotalQuoteStyle>
                TOTAL SAVINGS<sup>*</sup>
              </TotalQuoteStyle>
              <DisclaimerSpan>
                <sup>*</sup>Does not include Monthly Device Payments
              </DisclaimerSpan>
            </div>
            <div>
              <h2 style={{ margin: "0px" }}>
                <label style={{ fontSize: "12px" }}>$</label> 0.00
              </h2>
              <SavingsSpan>Savings/Month</SavingsSpan>
            </div>
            <div>
              <h2 style={{ margin: "0px" }}>
                <label style={{ fontSize: "12px" }}>$</label> 0.00
              </h2>
              <SavingsSpan>Savings/Year</SavingsSpan>
            </div>
          </StyledTableSavingsFooter>
          <QuickQuoteTitle isMarginBottom />
        </div>
      )}
    </div>
  );
};

const StyledContainer = styled.div`
  max-height: 30rem;
`;

const SavingsSpan = styled.span`
  font-size: 10px;
  font-weight: 800;
`;

const TotalQuoteStyle = styled.h3`
  margin: 0px;
`;

const TableFooterStyles = css`
  & > div:first-child {
    width: 80%;
    text-align: left;
    margin-right: 10px;
  }

  & > div {
    width: 15%;
    text-align: right;
  }
`;

const StyledTableFooter = styled.div`
  ${TableFooterStyles}
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 0 10px 0 5px;
`;

const TableSavingsFooterStyles = css`
  & > div:first-child {
    width: 80%;
    text-align: left;
    margin-right: 10px;
    background-color: transparent;
  }

  & > div:nth-child(2) {
    border-right: 1px solid #e8e8e8;
  }

  & > div {
    width: 20%;
    text-align: center;
    background-color: white;
    padding: 3% 0 3% 0;
  }
`;

const StyledTableSavingsFooter = styled.div`
  ${TableSavingsFooterStyles}
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 10px;
  padding: 10px 0 10px 5px;
`;

const StyledTableHeading = styled.h5`
  margin: 5px 0px 5px 10px;
  text-transform: uppercase;
  font-weight: 800;
`;

const DisclaimerSpan = styled.span`
  font-size:13px;
`;

const tableColumnStyle = css`
  & > div:first-child {
    width: 5%;
    text-align: left;
    margin-right: 10px;
  }

  & > div:nth-child(2) {
    width: 75%;
    text-align: left;
  }

  & > div {
    width: 20%;
    text-align: right;
  }
`;

const StyledTableHeader = styled.div`
  ${tableColumnStyle}
  font-weight: 600;
  background: rgb(204, 204, 204);
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding:10px; 
`;

const CartItems = styled.div`
  ${tableColumnStyle}
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background: rgb(255, 255, 255);
  border-bottom: 0.02px dotted rgb(204, 204, 204);
  padding: ${({ isDefault }) => (isDefault ? "20px" : "10px")};
`;

export default CartSummary;
