export class Customer {
  constructor(sessionId, opportunityNumber, opportunityName) {
    this.sessionId = sessionId;
    this.account = new Account(
      null,
      null,
      null,
      new BillingAddress(null, null, null, null, null, null, null),
      null
    );
    this.contact = new Contact(null, null, null, null);
    this.site = new Site(null, this.contact, null, null, null);
    this.opportunityNumber = opportunityNumber;
    this.opportunityName = opportunityName;
  }
}

export class Account {
  constructor(id, siteId, name, billingAddress, taxExempt) {
    this.id = id;
    this.siteId = siteId;
    this.name = name;
    this.billingAddress = billingAddress;
    this.taxExempt = taxExempt;
  }
}

export class BillingAddress {
  constructor(id, address1, address2, city, state, country, postalCode) {
    this.id = id;
    this.address1 = address1;
    this.address2 = address2;
    this.city = city;
    this.state = state;
    this.country = country;
    this.postalCode = postalCode;
  }
}

export class Contact {
  constructor(id, name, email, phone, phoneExt, businessName) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.phone = phone;
    this.phoneExt = phoneExt;
    this.businessName = businessName;
  }
}

export class Site {
  constructor(name, siteAddress, region, corp, division) {
    this.name = name;
    this.siteAddress = siteAddress;
    this.region = region;
    this.corp = corp;
    this.division = division;
  }
}

export class CustomerContactInfo {
  constructor(
    name,
    businessName,
    address1,
    address2,
    city,
    state,
    zip,
    email,
    phoneNumber,
    phoneExt,
    isValidAddress,
  ) {
    this.name = name;
    this.businessName = businessName;
    this.address1 = address1;
    this.address2 = address2;
    this.city = city;
    this.state = state;
    this.zip = zip;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.phoneExt = phoneExt;
    this.isValidAddress = isValidAddress
  }
}

export class CustomerPhoneNumber {
  constructor(value, displayValue) {
    this.value = value;
    this.displayValue = displayValue;
  }
}

export class CustomerAddress {
  constructor(address1, address2, city, state, zip, isValidAddress) {
    this.address1 = address1;
    this.address2 = address2;
    this.city = city;
    this.state = state;
    this.zip = zip;
    this.isValidAddress = isValidAddress;
  }
}
