export class Device {
    constructor(deviceDetail, unit, payment, unitPrice = null, term = null) {
        this.deviceDetail = deviceDetail;
        this.units = unit;
        this.payment = payment;
        this.unitPrice = unitPrice;
        this.term = term;
    }
}

export class DeviceDetail {
    constructor(id, name, capacity) {
        this.id = id;
        this.name = name;
        this.capacity =capacity;
    }
}