import React from "react";
import styled from "styled-components";
import { currentMobileProviderList } from "../constants";
import TnTextBox from "../utility/tnTextBox";
import { formatPhoneNumber } from "../utility/helper";

const MobileDetails = ({ tns, setTns }) => {  

  const onTnChange = (newTn, i) => {
    const updatedTnsList = tns.map((tn, index) => {
      if (index === i) {
        const teleNum = { ...tn };

        if (teleNum.tn === null) {
          teleNum.tn = { displayValue: "", value: "" };
        }

        const plainTnValue = newTn.replace(/[^\d]/g, "");

        if (plainTnValue.length < 11) {
          teleNum.tn.value = plainTnValue;
          teleNum.tn.displayValue = formatPhoneNumber(plainTnValue);
          //reset validity status of the tn        
          teleNum.isValid = false;
        }

        return teleNum;
      }
      return tn;
    });

    setTns(updatedTnsList);
  };

  const onCspChange = (newCmp, i) => {
    const updatedTnsList = tns.map((tn, index) => {
      if (index === i) {
        const teleNum = { ...tn };
        teleNum.cmp = newCmp;
        //reset validity status of the tn        
        teleNum.isValid = false;
        return teleNum;
      }
      return tn;
    });

    setTns(updatedTnsList);
  };

  return (
    <MobileDetailsContainer>
      <MobileDetailsTitle>
        Populate the Phone Number(s) to be Ported:{" "}
      </MobileDetailsTitle>
      <MobileDetailsHeader>
        <div>Phone Number</div>
        <CurrentMobileProviderHeader>
          Current Mobile Provider
        </CurrentMobileProviderHeader>
        <div>Validated</div>
      </MobileDetailsHeader>
      <MobileListContainer>
        {tns.map((x, i) => (
          <MobileListItem key={x.id}>
            <TnTextBox
              id={`p${x.id}-input`}
              placeholder="Enter Phone Number"
              type="text"
              value={x.tn}
              handleChange={(e) => onTnChange(e.target.value, i)}
            />
            <SavingsDropdownStyle
              name="cmp"
              id={`p${x.id}-cmp`}
              value={x.cmp}
              onChange={(e) => onCspChange(+e.target.value, i)}
            >
              <option value="-1">Select Current Provider</option>
              {currentMobileProviderList.map((cmp, i) => {
                return (
                  <option key={i} value={cmp.id}>
                    {cmp.name}
                  </option>
                );
              })}
            </SavingsDropdownStyle>
            <input
              id={`p${x}-checkbox`}
              type="checkbox"
              checked={x.isValid}
              readOnly
              style={{ margin: "auto 0px auto 70px", width: "20px", height: "20px" }}
            />
          </MobileListItem>
        ))}
      </MobileListContainer>
    </MobileDetailsContainer>
  );
};

const MobileDetailsContainer = styled.div``;

const MobileDetailsTitle = styled.h4``;

const MobileListContainer = styled.ul`
  padding-left: 0px;
`;

const CurrentMobileProviderHeader = styled.div`
    margin: 0 80px 0 100px;
`;

const MobileDetailsHeader = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
`;

const MobileListItem = styled.li`
  display: grid;
  grid-template-columns: auto auto auto;
  margin-bottom: 10px;
`;

const SavingsDropdownStyle = styled.select`
  height: 32px;
  min-width: 220px;
  font-size: 14px;
  line-height: 1rem;
  background-position: right 8px center;
  background-size: auto;
  font-weight: 500;
  color: #000000;
  border: 2px solid #000000;
  background-color: #ffffff;
  border-radius: 0;
  background-repeat: no-repeat;
  min-width: auto;
`;

export default MobileDetails;
