import axios from 'axios';

// For GET requests
axios.interceptors.request.use(
    (req) => {
        // Add configurations here
        return req;
    },
    (err) => {
        return Promise.reject(err);
    }
);
 
// For POST requests
axios.interceptors.response.use(
    (res) => {
        // Add configurations here
        return res;
    },
    (err) => {
        return Promise.reject(err);
    }
);
