import React from "react";
import CartSummary from "./cartSummary";
import PropTypes from 'prop-types';
import QuickQuoteTitle from "../utility/title";
import QQButton from "../utility/Button";

const Cart = (props) => {
    return (
        <div>
            <QuickQuoteTitle titleName="CART SUMMARY" /><br/>
            <CartSummary cart={props.cart}/>
            <QQButton onClick={props.clearCart} btnText="Clear Cart" floatRight></QQButton>
        </div>
    );
};

Cart.protoType = {
    cart: PropTypes.object
}

export default Cart;