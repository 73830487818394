import axios from "axios";
import { getEnv } from "../utils/env";

const { REACT_APP_API_BASE_URL, REACT_APP_GET_QQ_URL, ...ENV } = getEnv();

export const GetEnv = () => ENV['HOST_ENV'];

const GetLocalToken = () => ENV['REACT_APP_TOKEN'];

export const LocallyAuthenticate = async () => {
    try {
        const url = REACT_APP_API_BASE_URL + REACT_APP_GET_QQ_URL;
        const response = await axios
        .post(
            url,
            {
            opportunityId: ENV['REACT_APP_OPPORTUNITY_ID'],
            sessionId: ENV['REACT_APP_SESSION_ID'],
            fxbuyflowsessionId: ENV['REACT_APP_FXBUYFLOW_SESSION_ID'],
            agentId: ENV['REACT_APP_AGENT_ID']
            },
            {
            headers: {
                Authorization: `Bearer ` + GetLocalToken(),
            },
            }
        );
        if (response.status === 200) {
            return response.data?.guid;
            //correlationIdParams = response.data?.guid;
        } else {
            alert("Un Authorized - Bearer token Expired!");
            return null;
        }
    } catch (error) {
        return Promise.reject(error);
    }
};