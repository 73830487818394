import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'rc-tooltip/assets/bootstrap.css';
import { qqHomeButton } from "../../../services/navigation";

const Headertitle = (props) => {
  const showOppsIcon = props.showOppLink ?? false;
  return (
    <div>
      <strong>{props.title}:</strong> {props.value}
      {showOppsIcon && (
        <OppButton onClick={() => qqHomeButton(props.setShowLoader, props.opportunityId)}>
          <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
        </OppButton>
      )}
    </div>
  );
};

const OppButton = styled.button`
  border: none;
  cursor: pointer;
  color: rgb(24, 144, 255);
`;

export default Headertitle;
