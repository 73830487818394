import React from "react";
import styled from "styled-components";
import Headertitle from "../landing/Header/headertitle";
import QuickQuoteTitle from "../utility/title";

const CustomerInfo = ({ customerInformation }) => {
  return (
    <CustomerSection>
      <QuickQuoteTitle
        titleName={customerInformation.account.name}
        isCenter="true"
        normalText={true}
      />
      <QQCustomerContainer>
        <div></div>
        <div>
          <Headertitle title="Primary Contact" />
          <QQSpan>{customerInformation.contact.name}</QQSpan>
          <QQSpan>{customerInformation.contact.phone}</QQSpan>
        </div>
        <div>
          <Headertitle title="Service Address" />
          <QQSpan>{customerInformation.site.siteAddress.address1}</QQSpan>
          <QQSpan>{customerInformation.site.siteAddress.address2}</QQSpan>
          <QQSpan>
              {customerInformation.site.siteAddress.city && customerInformation.site.siteAddress.city + ", "}
              {customerInformation.site.siteAddress.state}{" "}
              {customerInformation.site.siteAddress.postalCode}
            </QQSpan>
        </div>
      </QQCustomerContainer>
    </CustomerSection>
  );
};

const CustomerSection = styled.div`
  padding: 10px;
`;

const QQCustomerContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
`;

const QQSpan = styled.div`
  margin-top: 10px;
`;

export default CustomerInfo;
