import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from "react-router-dom";

const { BUILD_DATE, PACKAGE_VERSION } = process.env

if (window?.screen?.availWidth < 1920) {
  document.body.style.zoom = "80%";
}

console.info(
  `%c\u00A9 QuickQuote \nBuild Date: ${BUILD_DATE}\nPackage Version: ${PACKAGE_VERSION}`,
  'background: #83c5be; color: #1F1F1F; font-size: medium'
)

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

