export class Quote {
    constructor(opportunityId, unlimitedType, unlimited, UnlimitedIntro, UnlimitedPlus, UnlimitedPremium, byTheGig, tns, byod, imei, devices, savings, cart) {
        this.opportunityId = opportunityId;
        this.unlimitedType = unlimitedType;
        this.unlimited = unlimited;
        this.UnlimitedIntro = UnlimitedIntro;
        this.UnlimitedPlus = UnlimitedPlus;
        this.UnlimitedPremium = UnlimitedPremium;
        this.byTheGig = byTheGig;
        this.tns = tns;
        this.byod = byod;
        this.imei = imei;
        this.devices = devices;
        this.savings = savings;
        this.cart = cart;
    }
}

export class Unlimited {
    constructor(quantity, unitPrice) {
        this.quantity = quantity;
        this.unitPrice = unitPrice;
    }
}

export class UnlimitedIntro extends Unlimited {
    constructor(quantity, unitPrice, unlimitedComboUnitPriceList = []) {
        super(quantity, unitPrice);
        this.unlimitedComboUnitPriceList = unlimitedComboUnitPriceList;
    }
}

export class UnlimitedPlus extends Unlimited {
}

export class UnlimitedPremium extends Unlimited {
}

export class ByTheGig {
    constructor(dataUsage, quantity, unitPrice) {
        this.dataUsage = dataUsage;
        this.quantity = quantity;
        this.unitPrice = unitPrice;
    }
}

export class Byod {
    constructor(quantity) {
        this.quantity = quantity
    }
}

export class PortImei {
    constructor(imei) {
        this.imeiNums = imei;
    }
}

export class Device {
    constructor(deviceDetail, unit, payment) {
        this.deviceDetail = deviceDetail;
        this.units = unit;
        this.payment = payment;
    }
}

export class PortTn {
    constructor(portTn, tns) {
        this.portTn = portTn;
        this.tns = tns;
    }
}
export class DeviceDetail {
    constructor(id, name) {
        this.id = id;
        this.name = name;
    }
}

export class Savings {
    constructor(currentMonthlyBill, currentMobileProvider) {
        this.currentMonthlyBill = currentMonthlyBill;
        this.currentMobileProvider = currentMobileProvider;
    }
}

export class Cart {
    constructor(cartItems, totalMrc, totalNrc, monthlySavings = 0) {
        this.cartItems = cartItems;
        this.totalMrc = totalMrc;
        this.totalNrc = totalNrc;
        this.monthlySavings = monthlySavings;
    }
}

export class CartItem {
    constructor(id, category, name, units, mrc, nrc) {
        this.id = id;
        this.category = category;
        this.name = name;
        this.units = units;
        this.mrc = mrc;
        this.nrc = nrc;
    }
}
