import { POST_VALIDATE_IMEI, POST_QQ_STANDARD_ADDRESS } from "../constants/urls";
import axios from "axios";

export const ValidateImei = async (payload) => {
    try {
        const response = await axios.post(POST_VALIDATE_IMEI, payload);
        const validateResponse = {
            responseData: response.data,
            responseStatus: (response.status === 200) ? true : false 
        };
        return validateResponse;        
    } catch (error) {
        return Promise.reject(error);
    }
};

export const ValidateAddress = async (payload) => {
    try {
        const response = await axios.post(POST_QQ_STANDARD_ADDRESS, payload);
        const validateResponse = {
            responseData: response.data,
            responseStatus: (response.status === 200) ? true : false 
        };
        return validateResponse;        
    } catch (error) {
        return Promise.reject(error);
    }
};