import React from "react";
import styled from "styled-components";

const WhiteCard = (props) => {
  return <Card>{props.children}</Card>;
};

const Card = styled.div`
  padding-top: 10px;
  background-color: white;
  padding-bottom: 6px;
`;

export default WhiteCard;
