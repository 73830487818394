import React, { useState, useRef, useEffect } from "react";
import '../styles/searchableDropdown.css';
import { defaultDeviceOption } from "../constants";

const SearchableDropdown = ({options, prompt, value, onChange, id, label}) => {
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState("");
    const ref = useRef(null);
    const dropdownOptions = [defaultDeviceOption, ...options];

    useEffect(() => {
        document.addEventListener('click', close);
        return () => document.removeEventListener('click', close);
    });

    const close = (e) => {
        setOpen(e && e.target === ref.current);
    };

    const filter = (options) => {
        return options.filter(
            (option) => 
                option[label].toLowerCase().indexOf(query.toLowerCase()) > -1
        );
    };

    const displayValue = () => {
        if(query.length > 0) return query;
        if(value) return value[label];
        return "";
    };

    return (
        <div className="dropdown">
            <div className="control" onClick={() => setOpen(prev => !prev)}>
                <div className="selected-value">
                    <input type="text"
                    ref={ref}
                    placeholder={value ? value[label] : prompt}
                    value={displayValue()}
                    onChange={e => {
                        setQuery(e.target.value);
                        onChange(null);
                    }}
                    onClick={() => setOpen(prev => !prev)} 
                    />
                </div>
                <div className={`arrow ${open ? "open" : null}`}></div>
            </div>
            <div className={`options ${open ? "open" : null}`}>
                {
                    filter(dropdownOptions).map(option => (
                        <div 
                            className={`option ${value === option ? "selected" : null}`}
                            key={option[id]}
                            onClick={() => {
                                setQuery("");
                                onChange(option);
                                setOpen(false);
                            }}
                        >
                            {option[label]}
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default SearchableDropdown;