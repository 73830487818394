import Dropdown from "../utility/searchableDropdown";
import React from "react";
import styled from "styled-components";
import UnitDropdown from "../utility/dropdown";
import { defaultPaymentDropdown/*, defaultDeviceSelection  */ } from "../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Device, DeviceDetail } from "../../entities/devices";

export default function DeviceSectionV2(props) {
  //#region Initializers
  const [paymentDropdownList, setPaymentDropdownList] = [props.paymentDropdownList, props.setPaymentDropdownList];
  const [devices, setDevices] = [props.devices, props.setDevice];
  const pcatDeviceList = props.deviceList;
  //#endregion
  
  //#region Handlers
  const addDevice = () => {
    //add max 10 device check and validation
    if (devices.length < props.totalLines) {
      setDevices([...devices, new Device(null, 0, "ONETIME")]);
      setPaymentDropdownList(paymentDropdownList => [...paymentDropdownList, defaultPaymentDropdown]);
    } else {
      alert("Sorry, you cannot add more than 10 device rows!");
    }
  };

  // const refreshDevice = (e) => {
  //   //reset to default devices when user clicks on reset icon
  //  setDevices([defaultDeviceSelection]);
  // }

  const resetDeviceRow = (device, index) => {
    device.deviceDetail = null;
    //should revert back payment options to default if device is made null
    const updatedPaymentDropdownList = paymentDropdownList.map((x, i) => i === index ? defaultPaymentDropdown : x);
    device.payment = "ONETIME";
    //revert units to zero
    device.units = 0;
    device.unitPrice = 0;
    device.term = 1;
    setPaymentDropdownList(updatedPaymentDropdownList);
  };

  const updateDevices = (updateFactor, newDevice, index) => {
    //setDevices

    //Device update
    if (updateFactor === "device") {
      const updatedDeviceList = devices.map((d, i) => {
        const device = {...d};
        if (index === i && !!newDevice) {
          if (newDevice.productCode === -1) {
            resetDeviceRow(device, index);
            return device;
          }

          device.deviceDetail = new DeviceDetail(
            newDevice.productCode,
            newDevice.name,
            newDevice.capacity
          );

          //reverting the payment option to default value          
          //updating the payments dropdown
          const d = pcatDeviceList.find(device => device.productCode === newDevice.productCode);
          device.payment = !!d ? d.paymentOptions[0].id : "ONETIME";
          const rawData = d.paymentOptions;
          const newPaymentOptions = preparePaymentOptions(rawData);
          const updatedPaymentDropdownList = paymentDropdownList.map((x, i) => i === index ? newPaymentOptions : x);
          setPaymentDropdownList(updatedPaymentDropdownList);

          //Updating the unitPrice on device change
          const paymentOptions = findPriceAndTerm(device, device.payment);
          device.unitPrice = paymentOptions.price;
          device.term = paymentOptions.term;
          return device;
        }
        if (index === i && !newDevice) {
          resetDeviceRow(device, index);
          return device;
        }
        
        return device;
      });
      setDevices(updatedDeviceList);
    }
  };

  const preparePaymentOptions = (rawData) => {
    const otherOptions = rawData.map(x => ({
      id: x.id,
      name: x.name
    }));
    const options = [...otherOptions]
    return options;
  };

  const updatePayment = (updateFactor, newPaymentValue, index, updatedDevice) => {
    //Payment update
    if (updateFactor === "payment") {
      const updatedDeviceList = devices.map((d, i) => {
        const device = {...d};
        if (index === i) {
          device.payment = newPaymentValue;
          //updating the unitPrice on payment method change as per the pcat response
          const paymentOptions = findPriceAndTerm(updatedDevice, newPaymentValue);
          device.unitPrice = paymentOptions.price;
          device.term = paymentOptions.term;
        }
        return device;
      });
      setDevices(updatedDeviceList);
    }
  };

  const findPriceAndTerm = (device, paymentMethod) => {
    let price = 0;
    let term = 1;

    if(!!device.deviceDetail) {
      const paymentOption = pcatDeviceList.find(x => x.productCode === device.deviceDetail.id).paymentOptions.find(x => x.id === paymentMethod);
      term = paymentOption.term;
      price = paymentOption.price;
      return { price, term };
    } 
    if(paymentMethod === 'recurring') {
      term = 24;
    }
    return { price, term }
  }

  const updateUnit = (updateFactor, newUnitValue, index) => {
    //Payment update
    if (updateFactor === "unit") {
      const updatedDeviceList = devices.map((d, i) => {
        const device = {...d};
        if (index === i) {
          device.units = +newUnitValue;
        }
        return device;
      });
      setDevices(updatedDeviceList);
    }
  };

  const removeDevice = (index) => {
    const updatedDeviceList = devices.filter((x, i) => index !== i);
    setDevices(updatedDeviceList);
  };

  const handleUpdateUnit = (value, i) => {
    updateUnit("unit", value, i);
  };
  //#endregion

  return (
    <div style={{ marginLeft: "20px" }}>
      {devices.map((device, i) => {
        return (
          <div key={i}>
            <DeviceRow>
              <FontAwesomeIcon
                icon="fa-solid fa-mobile-screen-button"
                style={{ fontSize: "24px", marginTop: "5px" }}
              />
              <Dropdown
                options={pcatDeviceList}
                prompt="Select Brand Device"
                value={device.deviceDetail}
                onChange={(option) => updateDevices("device", option, i)}
                id="productCode"
                label="name"
              />
              <PaymentDropdownStyle
                value={device.payment}
                onChange={(e) => updatePayment("payment", e.target.value, i, device)}
                // disabled={!device.deviceDetail}
              >
                {/* <option value="-1">Select payment</option>
                <option value="ONETIME">Pay in Full</option>
                <option value="RECURRING">Pay Monthly (for 24 months)</option> */}
                {paymentDropdownList[i].map(x => {
                  return (
                    <option value={x.id} key={x.id}>{x.name}</option>
                  );
                })}
              </PaymentDropdownStyle>
              {/* <QQTooltip toolTipText={toolTips.Payment.text} /> */}
              <UnitDropdown
                list={props.linesList}
                id="device_lines"
                name="device"
                selected={device.units}
                handleChange={(updatedUnit) => handleUpdateUnit(updatedUnit, i)}
                // disabled={!device.deviceDetail}
              />
              {i !== 0 ? (
                <RemoveDeviceButton onClick={() => removeDevice(i)} index={i}>
                  <FontAwesomeIcon icon="fa-solid fa-minus" size="lg" />
                </RemoveDeviceButton>
              ) : (
                <RemoveDeviceButton index={i}></RemoveDeviceButton>
              )}
            </DeviceRow>
          </div>
        );
      })}
      <AddDeviceButton onClick={addDevice} disabled={devices.length === props.totalLines}>
        <FontAwesomeIcon icon="fa-solid fa-plus" size="xl" />
      </AddDeviceButton>
      {/* <AddDeviceButton onClick={refreshDevice}>
        <FontAwesomeIcon icon="fa-solid fa-arrows-rotate" size="xl" />
      </AddDeviceButton> */}
    </div>
  );
}

//#region styled-components
const RemoveDeviceButton = styled.span`
  cursor: pointer;
  margin-left: 10px;
  margin-top: 7px;
  width: ${(props) => props.index === 0 && "11px"};
`;

const AddDeviceButton = styled.button`
  cursor: pointer;
  float: right;
  background-color: white;
  border: none;
`;

const DeviceRow = styled.div`
  display: grid;
  grid-template-columns: 40px 380px 320px 100px 40px;
  padding-right: 0px;
`;

const PaymentDropdownStyle = styled.select`
  height: 32px;
  min-width: 220px;
  font-size: 14px;
  line-height: 1rem;
  background-position: right 8px center;
  background-size: auto;
  font-weight: 500;
  color: #000000;
  border: 2px solid #000000;
  background-color: #ffffff;
  border-radius: 0;
  background-repeat: no-repeat;
  margin-right: 10px;
`;
  //#endregion
//min-width: auto;
