import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import DropDown from "../utility/dropdown";
import PortImei from "./portImei";

const defaultImeiOption = {
  id: 0,
  imei: "",
  isValid: false,
};

const Byod = ({ byodProps }) => {
  const { imei, setImei } = byodProps;

  const handleByodLinesChange = (lines) => {
    createImeiList(lines);
    byodProps.setByodLines(lines);
  };

  const createImeiList = (num) => {
    let counter = 0;
    let newImeiList = [];

    if (imei.length === 0) {
      while (counter < num) {
        newImeiList.push({ ...defaultImeiOption, id: counter });
        counter++;
      }
    } else if (num > imei.length) {
      counter = num - imei.length;
      newImeiList = [...imei];
      //to start the counting from
      let id = imei.length;
      while (counter > 0) {
        newImeiList.push({ ...defaultImeiOption, id: id++ });
        counter--;
      }
    } else {
      counter = imei.length - num;
      newImeiList = [...imei];
      while (counter > 0) {
        newImeiList.pop();
        counter--;
      }
    }

    setImei(newImeiList);
  };

  return (
    <MainContainer>
      <DeviceWrapper>
        <DeviceRow>
          <DevicesLabel>Bring Your Own</DevicesLabel>
          <ByodContainer>
            <FontAwesomeIcon
              icon="fa-solid fa-mobile-screen-button"
              style={{ fontSize: "24px", marginTop: "5px" }}
            />
            <DevicesLabel>Phone</DevicesLabel>
            <DropDown
              list={byodProps.linesList}
              id="byod"
              name="byod"
              selected={byodProps.byodLines}
              handleChange={(e) => handleByodLinesChange(e)}
            />
          </ByodContainer>
        </DeviceRow>
      </DeviceWrapper>
      {byodProps.byodLines > 0 ? (
        <PortImei
          byodLines={byodProps.byodLines}
          imei={byodProps.imei}
          setImei={byodProps.setImei}
        />
      ) : null}
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 450px auto;
`;

const ByodContainer = styled.div`
  display: grid;
  grid-template-columns: 35px 90px auto;
  margin-left: 20px;
`;

const DeviceRow = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 50px;
`;

const DevicesLabel = styled.h4`
  margin: 10px;
  font-weight: 400;
  font-size: 16px;
`;

const DeviceWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
`;

export default Byod;
