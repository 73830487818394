import React from "react";
import styled from "styled-components";

const DropDown = (props) => {
  return (
    <QQDropdown
      name={props.name}
      id={props.id}
      value={`${props.selected}`}
      onChange={(e) => props.handleChange(+e.target.value)}
      {...props}
    >
      {props.list.map((x) => (
        <option key={x} value={x}>
          {x}
        </option>
      ))}
    </QQDropdown>
  );
};

const QQDropdown = styled.select`
  height: 32px;
  min-width: 220px;
  font-size: 14px;
  line-height: 1rem;
  background-position: right 8px center;
  background-size: auto;
  font-weight: 500;
  color: #000000;
  border: 2px solid #000000;
  background-color: #ffffff;
  border-radius: 0;
  background-repeat: no-repeat;
  min-width: auto;
  width:70px;
`;

export default DropDown;
