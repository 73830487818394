import React from "react";
import Devices from "./devices";
import Lines from "./lines";
import Savings from "./savings";
import PropTypes from "prop-types";
import styled from "styled-components";
import QQButton from "../utility/Button";
import QuickQuoteTitle from "../utility/title";
import ContactInformation from "./contactInformation";

const Configuration = ({ configProps, updateCart }) => {
  //#region - Lines Props
  const {
    unlimitedLines, 
    setUnlimitedLines, 
    unlimitedIntroLines, 
    setUnlimitedIntroLines, 
    unlimitedPlusLines, 
    setUnlimitedPlusLines, 
    unlimitedPremiumLines, 
    setUnlimitedPremiumLines, 
    unlimitedType,
    setUnlimitedType,
    unlimitedTypeList,
    gigLines, 
    setGigLines,
    gigDataUsage, 
    setGigDataUsage, 
    gigDataUsageList, 
    toolTips,
    tns, 
    setTns,
    setShowLoader,
    setValidationSpinner,
    portTn, 
    setPortTn, 
    setIsValid, 
    setOpen, 
    setErrorMessage,    
    setIsSuccess,
    setSuccessMessage,
    showDialog,
    linesList,
    toggleSection,
    setToggleSection,
    isValidAddress,
    setIsValidAddress,
  } = configProps;
  
  const linesProps = {
    unlimitedLines,
    setUnlimitedLines,
    unlimitedIntroLines, 
    setUnlimitedIntroLines, 
    unlimitedPlusLines, 
    setUnlimitedPlusLines, 
    unlimitedPremiumLines, 
    setUnlimitedPremiumLines,
    unlimitedType,
    setUnlimitedType,
    unlimitedTypeList,
    gigLines,
    setGigLines,
    gigDataUsage,
    setGigDataUsage,
    gigDataUsageList,
    toolTips,
    tns,
    setTns,
    setShowLoader,
    setValidationSpinner,
    portTn, 
    setPortTn, 
    setIsValid, 
    setOpen, 
    setErrorMessage,    
    setIsSuccess,
    setSuccessMessage,
    showDialog,
    linesList,
    toggleSection,
    setToggleSection
  };
  //#endregion

  //#region - ContactInfo Props
  const { customerContactInformation, setContactInformation, isContactInfoEdited, setContactInfoEdited } = configProps;

  const contactInfoProps = {
    customerContactInformation,
    setContactInformation,
    isContactInfoEdited,
    setContactInfoEdited,
    toggleSection,
    setToggleSection,
    isValidAddress,
    setIsValidAddress
  };
  //#endregion

  //#region - Devices Props
  const {
    byodLines, 
    setByodLines, 
    deviceLines, 
    setDeviceLines, 
    device, 
    setDevice, 
    paymentMethod, 
    setPaymentMethod, 
    paymentDropdownList, 
    setPaymentDropdownList,
    deviceList,
    totalLines,
    imei,
    setImei,
  } = configProps;

  const devicesProps = {
    byodLines, 
    setByodLines, 
    deviceLines, 
    setDeviceLines, 
    device, 
    setDevice, 
    paymentMethod, 
    setPaymentMethod, 
    setIsValid, 
    setOpen, 
    setErrorMessage, 
    paymentDropdownList, 
    setPaymentDropdownList,
    deviceList,
    toolTips,
    showDialog,
    linesList,
    totalLines,
    imei,
    setImei,
    setShowLoader,
    setValidationSpinner,    
    setIsSuccess,
    setSuccessMessage,
    toggleSection,
    setToggleSection
  };
  //#endregion

  //#region - Savings Props
  const {
    cms,
    setCms,
    csp,
    setCsp,
    cspList
  } = configProps;

  const savingsProps = {
    cms,
    setCms,
    csp,
    setCsp,
    cspList,
    toggleSection,
    setToggleSection
  };
  //#endregion
  return (
    <>
      <FilterSection>
        <QuickQuoteTitle titleName="Comcast Business Mobile" />
        <ContactInformation 
          contactInfoProps={contactInfoProps}
        />
        <Lines
          linesProps={linesProps}
        />
        <Devices
          devicesProps={devicesProps}
        />
        <Savings
          savingsProps={savingsProps}
        />
      </FilterSection>
      <QQButton onClick={updateCart} btnText="Update Cart" floatRight />
    </>
  );
};

//#region Configuration proptypes
Configuration.propTypes = {
  unlimitedLines: PropTypes.number,
  unlimitedIntroLines:PropTypes.number,
  unlimitedPlusLines: PropTypes.number,
  unlimitedPremiumLines: PropTypes.number,
  unlimitedType:PropTypes.number,
  unlimitedTypeList:PropTypes.array,
  gigLines: PropTypes.number,
  gigDataUsage: PropTypes.number,
  byodLines: PropTypes.number,
  deviceLines: PropTypes.number,
  device: PropTypes.array,
  cms: PropTypes.number,
  csp: PropTypes.string,
  paymentMethod: PropTypes.string,
  paymentDropdownList: PropTypes.array,
  gigDataUsageList: PropTypes.array,
  cspList: PropTypes.array,
  deviceList: PropTypes.array,
  setUnlimitedLines: PropTypes.func,
  setUnlimitedIntroLines: PropTypes.func,
  setUnlimitedPlusLines: PropTypes.func,
  setUnlimitedPremiumLines: PropTypes.func,
  setUnlimitedType: PropTypes.func,
  setGigLines: PropTypes.func,
  setGigDataUsage: PropTypes.func,
  setByodLines: PropTypes.func,
  setDeviceLines: PropTypes.func,
  setDevice: PropTypes.func,
  setCms: PropTypes.func,
  setCsp: PropTypes.func,
  updateCart: PropTypes.func,
  setPaymentMethod: PropTypes.func,
  setIsValid: PropTypes.func,
  setErrorMessage: PropTypes.func,
  setOpen: PropTypes.func,
  setPaymentDropdownList: PropTypes.func,
  toolTips: PropTypes.array,
  tns: PropTypes.array,
  setTns: PropTypes.func,
  setShowLoader: PropTypes.func,
  setValidationSpinner: PropTypes.func,
  customerContactInformation: PropTypes.array,
  setContactInformation: PropTypes.array,
  isContactInfoEdited: PropTypes.bool,
  setContactInfoEdited: PropTypes.bool,
  portTn: PropTypes.number, 
  setPortTn: PropTypes.func,  
  setIsSuccess: PropTypes.func,
  setSuccessMessage: PropTypes.func,
  showDialog: PropTypes.func,
  linesList: PropTypes.array,
  totalLines: PropTypes.number,
  imei: PropTypes.array,
  setImei: PropTypes.func,
  toggleSection: PropTypes.bool,
  setToggleSection: PropTypes.bool,
  isValidAddress: PropTypes.bool,
  setIsValidAddress: PropTypes.bool
};
//#endregion

//#region styled-components
const FilterSection = styled.div`
  display: grid;
  grid-template-columns: auto;
`;
//#endregion

export default Configuration;
